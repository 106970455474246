import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router";

import ModalCloser from "../../main/components/ModalCloser";

import { layout, boxShadow } from "../../main/styles/layout";
import { h5 } from "../../main/styles/typography";
import anchor from "../../main/images/tooltip-anchor.svg";
import COLORS from "../../main/constants/colors";

const AgentInfoTooltip = ({ visible, closeTooltip }) => (
  <TooltipWrapper visible={visible}>
    <Anchor />
    <Tooltip>
      <Title>Your Info</Title>
      <Text>
        You can edit this information by going to{" "}
        <LinkToAccountSettings to="/account-settings" target="_blank">
          your profile
        </LinkToAccountSettings>
      </Text>
    </Tooltip>
    <ModalCloser onClick={closeTooltip} />
  </TooltipWrapper>
);

AgentInfoTooltip.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeTooltip: PropTypes.func.isRequired
};

// Styled Components
const TooltipWrapper = styled.div`
  ${layout("column", "flex-end")}
  ${(props) =>
    !props.visible &&
    css`
      display: none;
    `}
  position: absolute;
  top: 90px;
  right: 10px;
  font-family: "Noto Sans", Helvetica, Arial, sans-serif;
  color: ${COLORS.darkGray};
  z-index: 4;
  @media (max-width: 599px) {
    top: 100px;
    right: auto;
  }
`;

const Anchor = styled.div`
  width: 26px;
  height: 16px;
  background-image: url(${anchor});
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 2;
  margin-right: 12px;
  @media (max-width: 599px) {
    margin: 0 auto;
  }
`;

const Tooltip = styled.div`
  position: relative;
  ${boxShadow(0, 1, 4, "rgba(0,0,0,0.2)")}
  width: 260px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${COLORS.white};
`;

const Title = styled.div`
  padding: 10px 15px;
  box-sizing: border-box;
  ${h5()}
  border-bottom: 1px solid ${COLORS.lighterGray};
`;

const Text = styled.p`
  padding: 10px 15px 20px 15px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 24px;
`;

const LinkToAccountSettings = styled(Link)`
  text-decoration: none;
  color: ${COLORS.primary};
`;

export default AgentInfoTooltip;
