import PropTypes from "prop-types";
import React from "react";
import Textarea from "react-textarea-autosize";
import styled, { css } from "styled-components";
import { layout } from "../../../main/styles/layout";

const Buyer7 = ({
  design,
  agent,
  AgentInfo,
  Title,
  Subtitle,
  PrimaryInput,
  Footer
}) => {
  const { backgroundUrl } = design;

  return (
    <LandingPageWrapper bgImg={backgroundUrl}>
      <OpacityMask />
      <AgentInfo agent={agent} design={design} />
      <MainContent>
        <Title
          TitleWrapper={TitleWrapper}
          TitleInput={TitleInput}
          TitleText={TitleText}
          design={design}
        />
        <Subtitle
          SubtitleWrapper={SubtitleWrapper}
          SubtitleInput={SubtitleInput}
          SubtitleText={SubtitleText}
          design={design}
        />
        <PrimaryInput />
      </MainContent>
      <FooterWrapper>
        <Footer
          dreNumber={agent.dreNumber}
          website={agent.website}
          design={design}
        />
      </FooterWrapper>
    </LandingPageWrapper>
  );
};

Buyer7.propTypes = {
  design: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  AgentInfo: PropTypes.elementType.isRequired,
  Title: PropTypes.elementType.isRequired,
  Subtitle: PropTypes.elementType.isRequired,
  PrimaryInput: PropTypes.elementType.isRequired,
  Footer: PropTypes.elementType.isRequired
};

// Styled Components
const LandingPageWrapper = styled.div`
  ${layout("column", "center")}
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  background-image: ${(props) => `url(${props.bgImg})`};
  @media (max-width: 599px) {
    padding: 0 15px;
    width: auto;
  }
`;

const OpacityMask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    #48a0e3 100%
  );
`;

const MainContent = styled.div`
  ${layout("column", "center")}
  position: relative;
  z-index: 1;
  top: 235px;
  @media (max-width: 599px) {
    top: 183px;
  }
`;

const FooterWrapper = styled.div`
  ${layout("row", "center", "center")}
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 36px 0;
  width: 100%;
  @media (max-width: 599px) {
    margin: 24px 0;
  }
`;

const TitleWrapper = styled.div`
  ${layout("row", "center", "center")}
  width: 100%;
  margin-bottom: 10px;
  padding: 0 24px;
  box-sizing: border-box;
  ${(props) =>
    props.customizable &&
    css`
      width: auto;
      border: 1px dashed #00e6c3;
      &:hover {
        border: 1px solid #00e6c3;
      }
    `}
  @media (max-width: 599px) {
    margin-bottom: 30px;
  }
`;

const TitleInput = styled(Textarea)`
  font-size: 40px;
  line-height: 52px;
  min-height: 52px;
  width: 100%;
  max-width: 1140px;
  font-weight: 600;
  border: none;
  outline: none;
  resize: none;
  text-align: center;
  background: transparent;
  text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.3);
  overflow-wrap: break-word;
  @media (max-width: 599px) {
    font-size: 26px;
    line-height: 36px;
    font-weight: 400;
  }
`;

const TitleText = styled.h1`
  font-size: 40px;
  line-height: 52px;
  width: 100%;
  max-width: 1140px;
  font-weight: 600;
  text-align: center;
  text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.3);
  overflow-wrap: break-word;
  @media (max-width: 599px) {
    font-size: 26px;
    line-height: 36px;
    font-weight: 400;
  }
`;

const SubtitleWrapper = styled.div`
  ${layout("row", "center", "center")}
  width: 100%;
  margin-bottom: 33px;
  padding: 8px 24px;
  box-sizing: border-box;
  ${(props) =>
    props.customizable &&
    css`
      width: auto;
      border: 1px dashed #00e6c3;
      &:hover {
        border: 1px solid #00e6c3;
      }
    `}
  @media (max-width: 599px) {
    margin-bottom: 34px;
    padding: 0;
  }
`;

const SubtitleInput = styled(Textarea)`
  font-size: 15px;
  line-height: 20px;
  min-height: 20px;
  height: 20px;
  width: 100%;
  max-width: 1140px;
  border: none;
  outline: none;
  resize: none;
  text-align: center;
  background: transparent;
  text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.3);
  overflow-wrap: break-word;
  @media (max-width: 599px) {
    font-size: 13px;
    line-height: 20px;
  }
`;

const SubtitleText = styled.p`
  font-size: 15px;
  line-height: 20px;
  width: 100%;
  max-width: 1140px;
  text-align: center;
  text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.3);
  overflow-wrap: break-word;
  @media (max-width: 599px) {
    font-size: 13px;
    line-height: 20px;
  }
`;

export default Buyer7;
