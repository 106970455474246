import PropTypes from "prop-types";
import React, { Component } from "react";

import PageTitle from "./PageTitle";
import PageSubtitle from "./PageSubtitle";
import PageAgentInfo from "./PageAgentInfo";
import PageAttractFooter from "./PageAttractFooter";

import { getPageBackground } from "../../main/constants/designDefaults";
import ArchivedPageBanner from "./ArchivedPageBanner";

import styled from "styled-components";
import { layout } from "../../main/styles/layout";

class Page extends Component {
  componentDidMount() {
    this.props.onLoaded();
  }
  render() {
    const {
      DesignComponent,
      PrimaryInput,
      design,
      designId,
      agent,
      active,
      children
    } = this.props;

    if (!active) {
      return <ArchivedPageBanner agent={agent} />;
    }

    const backgroundUrl = design.backgroundUrl || getPageBackground(designId);

    return (
      <PageWrapper>
        <DesignComponent
          design={{ ...design, backgroundUrl }}
          agent={agent}
          AgentInfo={PageAgentInfo}
          Title={PageTitle}
          Subtitle={PageSubtitle}
          PrimaryInput={PrimaryInput}
          Footer={PageAttractFooter}
        />
        {children}
      </PageWrapper>
    );
  }
}

Page.propTypes = {
  design: PropTypes.object.isRequired,
  designId: PropTypes.number.isRequired,
  agent: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  DesignComponent: PropTypes.func.isRequired,
  PrimaryInput: PropTypes.elementType.isRequired,
  children: PropTypes.node,
  onLoaded: PropTypes.func.isRequired
};

// Styled Components
const PageWrapper = styled.div`
  ${layout("column", "center")}
  width: 100%;
  height: 100%;
`;

export default Page;
