import PropTypes from "prop-types";
import React from "react";

import styled from "styled-components";
import { layout, boxShadow } from "../../main/styles/layout";
import COLORS from "../../main/constants/colors";

import addressIcon from "../images/icon-address-search.svg";

const PrimaryAddress = ({
  design,
  inputValue,
  customizable,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  onSubmit
}) => {
  if (customizable) {
    return (
      <PrimaryInputWrapper>
        <Input
          disabled
          type="text"
          font={design.font}
          placeholder={"Enter your property address"}
        />
        <CustomizeSubmitButton
          // NOTE: Hackiness to make Customize Mobile view work consistently
          style={{
            backgroundColor: design.accentColor,
            backgroundImage: `url(${addressIcon})`
          }}
        />
      </PrimaryInputWrapper>
    );
  }

  return (
    <PrimaryInputWrapper>
      <Input
        autoFocus
        type="text"
        value={inputValue}
        font={design.font}
        placeholder={"Enter your property address"}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <SubmitButton
        bgColor={design.accentColor}
        bgImg={addressIcon}
        onClick={onSubmit}
      />
    </PrimaryInputWrapper>
  );
};

PrimaryAddress.propTypes = {
  design: PropTypes.object.isRequired,
  customizable: PropTypes.bool,
  inputValue: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onSubmit: PropTypes.func
};

// Styled Components
export const PrimaryInputWrapper = styled.div`
  ${layout("row", "center")}
  width: 100%;
  max-width: 556px;
  min-height: 56px;
  ${boxShadow()}
  margin-bottom: 28px;
  border-radius: 3px;
  overflow: hidden;
  z-index: 1;
  @media (max-width: 599px) {
    margin-bottom: 30px;
  }
`;

export const Input = styled.input`
  background: ${COLORS.white};
  border-radius: 3px 0 0 3px;
  font-size: 15px;
  font-family: ${(props) => props.font};
  color: ${COLORS.mediumGray};
  letter-spacing: 0;
  background: ${COLORS.white};
  outline: none;
  border: none;
  width: 100%;
  min-height: 56px;
  padding: 0 17px;
  margin: 0;
  &::placeholder {
    color: ${COLORS.lightGray};
  }
`;

export const SubmitButton = styled.button`
  ${layout("row", "center", "center")}
  margin: 0;
  outline: none;
  border: none;
  color: ${COLORS.white};
  min-width: 56px;
  min-height: 56px;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  background-image: ${(props) => `url(${props.bgImg})`};
  background-color: ${(props) => props.bgColor};
`;

export const CustomizeSubmitButton = styled.button`
  ${layout("row", "center", "center")}
  margin: 0;
  outline: none;
  border: none;
  color: ${COLORS.white};
  min-width: 56px;
  min-height: 56px;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
`;

export default PrimaryAddress;
