import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AddressTypeahead from "../components/AddressTypeahead";

import { submitPrimary } from "../actions/formActions";
import {
  setAddressQuery,
  selectResult,
  updateResults,
  clearResults,
  traverseUp,
  traverseDown,
  setActiveIndex
} from "../actions/typeaheadActions";

export const mapStateToProps = ({ page, address, typeahead }) => {
  const { design } = page;
  const { results, activeIndex, query } = typeahead;
  return {
    query,
    design,
    address,
    results,
    activeIndex
  };
};

export const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      traverseUp,
      traverseDown,
      setActiveIndex,
      setAddressQuery,
      updateResults,
      clearResults,
      selectResult,
      submitPrimary
    },
    dispatch
  );
};

const AddressTypeaheadContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressTypeahead);

export default AddressTypeaheadContainer;
