import REGEX from "../constants/regex";

export const validateField = (field) => {
  if (field.required && !field.value) {
    return Promise.resolve(`${field.title} is required`);
  }
  let err = "";
  switch (field.name) {
    // This is the place to add email, phone validations, etc
    case "email": {
      if (!field.value.match(REGEX.email)) {
        err = "Please enter a valid email";
      }
      break;
    }
  }
  return Promise.resolve(err);
};

// Returns a promise, passes an object of {field1:error1, field2:error2}
export const validateForm = (fields) => {
  const errors = fields.map((field) => validateField(field));
  return Promise.all(errors).then((errArr) => {
    return errArr.reduce((errs, err, i) => {
      return { ...errs, [fields[i].name]: err };
    }, {});
  });
};

export default {
  field: validateField,
  form: validateForm
};
