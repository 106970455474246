import * as types from "../constants/actionTypes";

export const address = (
  state = {
    id: "",
    value: "",
    coordinates: []
  },
  action
) => {
  switch (action.type) {
    case types.SET_ADDRESS:
      return action.address;
    case types.CAPTURE_LEAD_SUCCESS:
      return {
        ...state,
        value: action.address ? action.address : state.address,
        coordinates:
          action.lon && action.lat
            ? [action.lon, action.lat]
            : state.coordinates
      };
    default:
      return state;
  }
};

export default address;
