import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Page from "../components/Page";
import AddressTypeaheadContainer from "../containers/AddressTypeaheadContainer";
import PrimaryEmailContainer from "../containers/PrimaryEmailContainer";
import { trackContentViewed } from "../actions/analyticsActions";

import { getDesignComponent } from "../components/design";

export const getPrimaryInput = (leadType) => {
  switch (leadType) {
    case "seller":
      return AddressTypeaheadContainer;
    case "buyer":
      return PrimaryEmailContainer;
    default:
      return PrimaryEmailContainer;
  }
};

export const mapStateToProps = ({ page, agent }) => {
  const { designId, design, leadType, active } = page;
  return {
    design,
    designId,
    agent,
    leadType,
    active,
    DesignComponent: getDesignComponent(leadType, designId),
    PrimaryInput: getPrimaryInput(leadType)
  };
};

export const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLoaded: trackContentViewed
    },
    dispatch
  );
};

const App = connect(mapStateToProps, mapDispatchToProps)(Page);

export default App;
