export const shadeColor = (color, percent) => {
  let f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    "#" +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
};

export const formatPhoneNumber = (s) => {
  let s2 = ("" + s).replace(/\D/g, "");
  let m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? s : `(${m[1]}) ${m[2]}-${m[3]}`;
};

export const formatPriceRange = (range) => {
  return range
    .split("-")
    .map((price) => `$${price}`)
    .join(" - ");
};
