import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const SIZES = {
  xsmall: 32,
  small: 48,
  medium: 67,
  large: 120
};

// sizes: 'xsmall', 'small', 'medium', 'large'
const Avatar = ({ url, size, shape }) => {
  return <Image src={url} alt="avatar" size={size} shape={shape} />;
};

Avatar.propTypes = {
  url: PropTypes.string.isRequired,
  size: PropTypes.string,
  shape: PropTypes.string
};

Avatar.defaultProps = {
  size: "small",
  shape: "circle"
};

// Styled Components
const Image = styled.img`
  width: ${(props) => `${SIZES[props.size]}px`};
  height: ${(props) => `${SIZES[props.size]}px`};
  border-radius: ${(props) =>
    props.shape === "circle" ? `${SIZES[props.size]}px` : "4px"};
  object-fit: cover;
`;

export default Avatar;
