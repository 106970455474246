import React from "react";

import { Motion, spring } from "react-motion";
import styled from "styled-components";

const BottomPanel = ({ children }) => {
  return children ? (
    <Motion defaultStyle={{ y: 100 }} style={{ y: spring(0) }}>
      {({ y }) => {
        return (
          <BottomPanelWrapper style={{ transform: `translateY(${y}%)` }}>
            {children}
          </BottomPanelWrapper>
        );
      }}
    </Motion>
  ) : null;
};

// Styled Component
const BottomPanelWrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: transparent;
  z-index: 10;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
`;

export default BottomPanel;
