import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import form from "./form";
import typeahead from "./typeahead";
import capture from "./capture";
import address from "./address";

export const rootReducer = combineReducers({
  form,
  address,
  typeahead,
  capture,
  agent: (state = {}) => state,
  page: (state = {}) => state,
  sessionId: (state = null) => state,
  routing: routerReducer
});

export default rootReducer;
