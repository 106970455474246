import styled from "styled-components";
import { layout } from "../../main/styles/layout";
import { h1, defaultText } from "../../main/styles/typography";

export const ConfirmLayout = styled.div`
  ${layout("column", "center")}
  flex-wrap: nowrap;
  max-width: 560px;
  margin: 0 auto;
  padding: 76px 15px;
  box-sizing: border-box;
  font-family: ${(props) => props.font};
  text-align: center;
  background-color: white;
  & h1 {
    ${h1()}
    margin-bottom: 20px;
  }
  & p {
    ${defaultText()}
    font-family: ${(props) => props.font};
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 44px;
    }
  }
  & a {
    font-family: ${(props) => props.font};
    color: inherit;
  }
`;
