import { push } from "react-router-redux";
import _every from "lodash.every";

import {
  getFormField,
  getFormFields,
  getFieldError,
  getFormErrors,
  getPageId,
  getPageGuid,
  getLeadData,
  getCaptureId,
  getAddress,
  getAddressLatLon,
  getAddressQuery
} from "../store/selectors";
import { createCapture, updateCapture } from "../lib/api";
import validate from "../lib/validate";
import { setResult } from "./typeaheadActions";
import { trackLeadSubmitted } from "./analyticsActions";
import * as types from "../constants/actionTypes";

export const setFieldValue = (name, value) => {
  return {
    type: types.SET_FIELD_VALUE,
    name,
    value
  };
};

export const changeFieldValue = (name, value) => {
  return (dispatch, getState) => {
    dispatch(setFieldValue(name, value));
    dispatch(setFieldTouched(name, true));
    return validate
      .field({ ...getFormField(getState(), name), value })
      .then((err) => {
        if (err) {
          dispatch(setFieldError(name, err));
          dispatch(setFormValid(false));
        } else {
          dispatch(clearFieldError(name));
        }
      });
  };
};

export const setFieldTouched = (name, touched) => {
  return {
    type: types.SET_FIELD_TOUCHED,
    name,
    touched
  };
};

export const clearFieldError = (name) => {
  return {
    type: types.CLEAR_FIELD_ERROR,
    name
  };
};

export const setFieldError = (name, err) => {
  return {
    type: types.SET_FIELD_ERROR,
    name,
    err
  };
};

export const setFormErrors = (errors) => {
  return {
    type: types.SET_FORM_ERRORS,
    errors
  };
};

export const setFormValid = (valid) => {
  return {
    type: types.SET_FORM_VALID,
    valid
  };
};

export const validateForm = () => {
  return (dispatch, getState) => {
    return validate
      .form(getFormFields(getState()))
      .then((errors) => {
        dispatch(setFormErrors(errors));
        const valid = _every(getFormErrors(getState()), (err) => err === "");
        dispatch(setFormValid(valid));
        return Promise.resolve(valid);
      })
      .catch((err) => console.error(err));
  };
};

export const submitPrimary = (name) => {
  return (dispatch, getState) => {
    if (!getFieldError(getState(), name)) {
      switch (name) {
        case "email":
          return dispatch(submitEmail());
        case "address":
          return dispatch(submitAddress());
      }
    }
    return Promise.resolve();
  };
};

export const submitEmail = () => {
  return (dispatch) => {
    dispatch(captureLead());
    return Promise.resolve();
  };
};

export const submitAddress = () => {
  return (dispatch, getState) => {
    const state = getState();
    const address = getAddress(state);
    const query = getAddressQuery(state);
    if (!address.value) {
      dispatch(
        setResult({
          id: "",
          value: query,
          coordinates: []
        })
      );
    }
    dispatch(captureLead());
    return Promise.resolve();
  };
};

export const submitForm = () => {
  return (dispatch) => {
    return dispatch(validateForm())
      .then((valid) => {
        if (valid) {
          dispatch(convertLead());
        }
      })
      .catch((err) => console.error(err));
  };
};

export const captureLead = () => {
  return (dispatch, getState) => {
    dispatch(captureLeadRequest());
    const state = getState();
    const pageId = getPageId(state);
    const guid = getPageGuid(state);
    const leadData = getLeadData(state);
    const fields = getFormFields(state);
    const addressLatLon = getAddressLatLon(state);

    // Optimistically go to the next step
    dispatch(
      fields.length < 2 ? push(`/${guid}/confirm`) : push(`/${guid}/fields`)
    );

    // Send a request to create the capture
    return createCapture(pageId, { ...leadData, ...addressLatLon })
      .then((res) => res.json())
      .then((json) => {
        dispatch(captureLeadSuccess(json));
        dispatch(trackLeadSubmitted());
        return Promise.resolve();
      })
      .catch((err) => dispatch(captureLeadFailure(err.message)));
  };
};

export const captureLeadRequest = () => {
  return {
    type: types.CAPTURE_LEAD_REQUEST
  };
};

export const captureLeadSuccess = ({ capture }) => {
  return {
    type: types.CAPTURE_LEAD_SUCCESS,
    id: capture.id,
    address: capture.data.address,
    lat: capture.data.lat,
    lon: capture.data.lon
  };
};

export const captureLeadFailure = (err) => {
  return {
    type: types.CAPTURE_LEAD_FAILURE,
    err
  };
};

export const convertLead = () => {
  return (dispatch, getState) => {
    dispatch(convertLeadRequest());
    const state = getState();
    const pageId = getPageId(state);
    const guid = getPageGuid(state);
    const leadData = getLeadData(state);
    const captureId = getCaptureId(state);

    return updateCapture(pageId, captureId, leadData)
      .then((res) => res.json())
      .then((json) => {
        dispatch(convertLeadSuccess(json));
        dispatch(push(`/${guid}/confirm`));
        dispatch(trackLeadSubmitted());
        return Promise.resolve();
      })
      .catch((err) => dispatch(convertLeadFailure(err)));
  };
};

export const convertLeadRequest = () => {
  return {
    type: types.CONVERT_LEAD_REQUEST
  };
};

export const convertLeadSuccess = (data) => {
  return {
    type: types.CONVERT_LEAD_SUCCESS,
    data
  };
};

export const convertLeadFailure = (err) => {
  return {
    type: types.CONVERT_LEAD_FAILURE,
    err
  };
};
