const DESIGNS = {
  seller: [
    {
      id: 0,
      thumbnail: require("../images/design_assets/thumbnails/seller/000.jpg"),
      settings: {
        title: "What's My Home Worth?",
        subtitle:
          "You'll receive your free comprehensive home report in just a few minutes",
        font: "'Open Sans', Helvetica, Arial, sans-serif",
        textColor: "#596473",
        accentColor: "#FF4D14",
        overlay: "dark"
      }
    },
    {
      id: 1,
      thumbnail: require("../images/design_assets/thumbnails/seller/001.jpg"),
      preview: require("../images/design_assets/previews/seller/001.jpg"),
      settings: {
        title: "What's My Home Worth?",
        subtitle:
          "You'll receive your free comprehensive home report in just a few minutes",
        font: "'Open Sans', Helvetica, Arial, sans-serif",
        textColor: "#596473",
        accentColor: "#20B575"
      }
    },
    {
      id: 2,
      thumbnail: require("../images/design_assets/thumbnails/seller/002.jpg"),
      preview: require("../images/design_assets/previews/seller/002.jpg"),
      settings: {
        title: "What’s My Home Worth?",
        subtitle:
          "You'll receive your free comprehensive home report in just a few minutes",
        font: "Roboto, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#9B34F6"
      }
    },
    {
      id: 3,
      thumbnail: require("../images/design_assets/thumbnails/seller/003.jpg"),
      preview: require("../images/design_assets/previews/seller/003.jpg"),
      settings: {
        title: "What's My Home Worth?",
        subtitle:
          "You'll receive your free comprehensive home report in just a few minutes",
        font: "Lato, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#E69715"
      }
    },
    {
      id: 4,
      thumbnail: require("../images/design_assets/thumbnails/seller/004.jpg"),
      preview: require("../images/design_assets/previews/seller/004.jpg"),
      settings: {
        title: "What's My Home Worth?",
        subtitle:
          "You’ll receive your free comprehensive home report in just a few minutes",
        font: "Montserrat, Helvetica, Arial, sans-serif",
        textColor: "#023545",
        accentColor: "#00C9C7"
      }
    },
    {
      id: 5,
      thumbnail: require("../images/design_assets/thumbnails/seller/005.jpg"),
      preview: require("../images/design_assets/previews/seller/005.jpg"),
      settings: {
        title: "Want to know what your home is really worth?",
        subtitle:
          "You'll receive your free comprehensive home report in just a few minutes",
        font: "Merriweather, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#009D5A"
      }
    },
    {
      id: 6,
      thumbnail: require("../images/design_assets/thumbnails/seller/006.jpg"),
      preview: require("../images/design_assets/previews/seller/006.jpg"),
      settings: {
        title: "What's My Home Worth?",
        subtitle:
          "You’ll receive your free comprehensive home report in just a few minutes",
        font: "Bitter, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#E69715"
      }
    },
    {
      id: 7,
      thumbnail: require("../images/design_assets/thumbnails/seller/007.jpg"),
      preview: require("../images/design_assets/previews/seller/007.jpg"),
      settings: {
        title: "What's My Home Worth?",
        subtitle:
          "You'll receive your free comprehensive report in just a few minutes",
        font: "Cabin, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#81AB00"
      }
    },
    {
      id: 8,
      thumbnail: require("../images/design_assets/thumbnails/seller/008.jpg"),
      preview: require("../images/design_assets/previews/seller/008.jpg"),
      settings: {
        title: "What's My Home Worth?",
        subtitle:
          "You’ll receive your free comprehensive home report in just a few minutes",
        font: "Cabin, Helvetica, Arial, sans-serif",
        textColor: "#44412A",
        accentColor: "#FF4D14"
      }
    },
    {
      id: 9,
      thumbnail: require("../images/design_assets/thumbnails/seller/009.jpg"),
      preview: require("../images/design_assets/previews/seller/009.jpg"),
      settings: {
        title: "What's My Home Worth?",
        subtitle:
          "You’ll receive your free comprehensive home report in just a few minutes",
        font: "Montserrat, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#478BFA"
      }
    },
    {
      id: 10,
      thumbnail: require("../images/design_assets/thumbnails/seller/010.jpg"),
      preview: require("../images/design_assets/previews/seller/010.jpg"),
      settings: {
        title: "What's My Condo Worth?",
        subtitle:
          "You’ll receive your free comprehensive home report in just a few minutes",
        font: "Lato, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#2384FF"
      }
    },
    {
      id: 11,
      thumbnail: require("../images/design_assets/thumbnails/seller/011.jpg"),
      preview: require("../images/design_assets/previews/seller/011.jpg"),
      settings: {
        title: "What's My Home Worth?",
        subtitle:
          "You’ll receive your free comprehensive home report in just a few minutes",
        font: "Cabin, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#61B514"
      }
    },
    {
      id: 12,
      thumbnail: require("../images/design_assets/thumbnails/seller/012.jpg"),
      preview: require("../images/design_assets/previews/seller/012.jpg"),
      settings: {
        title: "What's My Home Worth?",
        subtitle:
          "You’ll receive your free comprehensive home report in just a few minutes",
        font: "Catamaran, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#EC6868"
      }
    },
    {
      id: 13,
      thumbnail: require("../images/design_assets/thumbnails/seller/013.jpg"),
      preview: require("../images/design_assets/previews/seller/013.jpg"),
      settings: {
        title: "What's My Home Worth?",
        subtitle:
          "You’ll receive your free comprehensive home report in just a few minutes",
        font: "Catamaran, Helvetica, Arial, sans-serif",
        textColor: "#7C563C",
        accentColor: "#FF7347"
      }
    },
    {
      id: 14,
      thumbnail: require("../images/design_assets/thumbnails/seller/014.jpg"),
      preview: require("../images/design_assets/previews/seller/014.jpg"),
      settings: {
        title: "What’s My Southwest Home Worth?",
        subtitle:
          "You’ll receive your free comprehensive home report in just a few minutes",
        font: "Cabin, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#EC5252"
      }
    },
    {
      id: 15,
      thumbnail: require("../images/design_assets/thumbnails/seller/015.jpg"),
      preview: require("../images/design_assets/previews/seller/015.jpg"),
      settings: {
        title: "What’s My Home Worth In Surf City?",
        subtitle:
          "You’ll receive your free comprehensive home report in just a few minutes",
        font: "Lato, Helvetica, Arial, sans-serif",
        textColor: "#00316E",
        accentColor: "#004498"
      }
    },
    {
      id: 16,
      thumbnail: require("../images/design_assets/thumbnails/seller/016.jpg"),
      preview: require("../images/design_assets/previews/seller/016.jpg"),
      settings: {
        title: "What’s My Home In Paradise Worth?",
        subtitle:
          "You’ll receive your free comprehensive home report in just a few minutes",
        font: "Catamaran, Helvetica, Arial, sans-serif",
        textColor: "#004E53",
        accentColor: "#048E77"
      }
    }
  ],
  buyer: [
    {
      id: 0,
      thumbnail: require("../images/design_assets/thumbnails/buyer/000.jpg"),
      settings: {
        title: "Looking For The Perfect Home?",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "'Open Sans', Helvetica, Arial, sans-serif",
        textColor: "#566373",
        accentColor: "#FF4D14",
        overlay: "dark"
      }
    },
    {
      id: 1,
      thumbnail: require("../images/design_assets/thumbnails/buyer/001.jpg"),
      preview: require("../images/design_assets/previews/buyer/001.jpg"),
      settings: {
        title: "Looking For The Perfect Home?",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "'Open Sans', Helvetica, Arial, sans-serif",
        textColor: "#566373",
        accentColor: "#0BBA77"
      }
    },
    {
      id: 2,
      thumbnail: require("../images/design_assets/thumbnails/buyer/002.jpg"),
      preview: require("../images/design_assets/previews/buyer/002.jpg"),
      settings: {
        title: "Looking For The Perfect Home?",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "Roboto, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#9B34F6"
      }
    },
    {
      id: 3,
      thumbnail: require("../images/design_assets/thumbnails/buyer/003.jpg"),
      preview: require("../images/design_assets/previews/buyer/003.jpg"),
      settings: {
        title: "Looking For The Perfect Home?",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "Lato, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#E69715"
      }
    },
    {
      id: 4,
      thumbnail: require("../images/design_assets/thumbnails/buyer/004.jpg"),
      preview: require("../images/design_assets/previews/buyer/004.jpg"),
      settings: {
        title: "Looking For The Perfect Home?",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "Montserrat, Helvetica, Arial, sans-serif",
        textColor: "#023545",
        accentColor: "#00C9C7"
      }
    },
    {
      id: 5,
      thumbnail: require("../images/design_assets/thumbnails/buyer/005.jpg"),
      preview: require("../images/design_assets/previews/buyer/005.jpg"),
      settings: {
        title: "Looking For Your Next Home?",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "Merriweather, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#009D5A"
      }
    },
    {
      id: 6,
      thumbnail: require("../images/design_assets/thumbnails/buyer/006.jpg"),
      preview: require("../images/design_assets/previews/buyer/006.jpg"),
      settings: {
        title: "Looking For Your Next Home?",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "Bitter, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#E69715"
      }
    },
    {
      id: 7,
      thumbnail: require("../images/design_assets/thumbnails/buyer/007.jpg"),
      preview: require("../images/design_assets/previews/buyer/007.jpg"),
      settings: {
        title: "Looking For The Perfect Home?",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "Cabin, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#81AB00"
      }
    },
    {
      id: 8,
      thumbnail: require("../images/design_assets/thumbnails/buyer/008.jpg"),
      preview: require("../images/design_assets/previews/buyer/008.jpg"),
      settings: {
        title: "Looking For The Perfect Home?",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "Cabin, Helvetica, Arial, sans-serif",
        textColor: "#44412C",
        accentColor: "#FF4D14"
      }
    },
    {
      id: 9,
      thumbnail: require("../images/design_assets/thumbnails/buyer/009.jpg"),
      preview: require("../images/design_assets/previews/buyer/009.jpg"),
      settings: {
        title: "Looking For The Perfect Home?",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "Montserrat, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#478BFA"
      }
    },
    {
      id: 10,
      thumbnail: require("../images/design_assets/thumbnails/buyer/010.jpg"),
      preview: require("../images/design_assets/previews/buyer/010.jpg"),
      settings: {
        title: "Looking For The Ideal Condo?",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "Lato, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#2384FF"
      }
    },
    {
      id: 11,
      thumbnail: require("../images/design_assets/thumbnails/buyer/011.jpg"),
      preview: require("../images/design_assets/previews/buyer/011.jpg"),
      settings: {
        title: "Looking For Your Next Home?",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "Cabin, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#61B514"
      }
    },
    {
      id: 12,
      thumbnail: require("../images/design_assets/thumbnails/buyer/012.jpg"),
      preview: require("../images/design_assets/previews/buyer/012.jpg"),
      settings: {
        title: "Looking For Your Next Home?",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "Catamaran, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#EC6868"
      }
    },
    {
      id: 13,
      thumbnail: require("../images/design_assets/thumbnails/buyer/013.jpg"),
      preview: require("../images/design_assets/previews/buyer/013.jpg"),
      settings: {
        title: "Looking For The Perfect Beach Home?",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "Catamaran, Helvetica, Arial, sans-serif",
        textColor: "#7C563C",
        accentColor: "#FF7347"
      }
    },
    {
      id: 14,
      thumbnail: require("../images/design_assets/thumbnails/buyer/014.jpg"),
      preview: require("../images/design_assets/previews/buyer/014.jpg"),
      settings: {
        title: "Looking For Homes in The Southwest?",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "Cabin, Helvetica, Arial, sans-serif",
        textColor: "#FFFFFF",
        accentColor: "#EC5252"
      }
    },
    {
      id: 15,
      thumbnail: require("../images/design_assets/thumbnails/buyer/015.jpg"),
      preview: require("../images/design_assets/previews/buyer/015.jpg"),
      settings: {
        title: "Live Where Others Vacation",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "Lato, Helvetica, Arial, sans-serif",
        textColor: "#00316E",
        accentColor: "#004498"
      }
    },
    {
      id: 16,
      thumbnail: require("../images/design_assets/thumbnails/buyer/016.jpg"),
      preview: require("../images/design_assets/previews/buyer/016.jpg"),
      settings: {
        title: "Live Where Others Vacation",
        subtitle:
          "With the fastest listing alerts directly from the MLS, be the first to know when your dream home hits the market.",
        font: "Lato, Helvetica, Arial, sans-serif",
        textColor: "#004E52",
        accentColor: "#048E77"
      }
    }
  ]
};

// NOTE: Filtering out the customizable designs with id 0
export const getDesignsForType = (leadType) =>
  DESIGNS[leadType].filter((d) => d.id !== 0);

export const getDesignSettings = (leadType, id) => {
  return DESIGNS[leadType].find((design) => design.id === id).settings;
};

export const getDesignThumb = (leadType, id) => {
  return DESIGNS[leadType].find((design) => design.id === id).thumbnail;
};

export const padToThree = (number) => ("00" + number).slice(-3);

export const getPageBackground = (designId) => {
  if (designId === 0) {
    return "";
  }
  const imgId = padToThree(designId);
  return require(`../images/design_assets/backgrounds/${imgId}`);
};
