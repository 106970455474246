import { combineReducers } from "redux";
import * as types from "../constants/actionTypes";

const initialState = {
  id: 0
};

export const id = (state = initialState.id, action) => {
  switch (action.type) {
    case types.CAPTURE_LEAD_SUCCESS:
      return action.id;
    default:
      return state;
  }
};

const capture = combineReducers({
  id
});

export default capture;
