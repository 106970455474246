import COLORS from "../constants/colors";
import { css } from "styled-components";

export const btn = () => css`
  outline: none;
  border: none;
  border-radius: 3px;
  padding: 9px 16px;
  font-family: "Noto Sans", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0;
  cursor: pointer;
`;

export const btnPrimary = () => css`
  ${btn()}
  background-color: ${COLORS.primary};
  color: ${COLORS.white};
  border-bottom: 2px solid ${COLORS.primaryDark};
  transition: background-color .3s;
  &:hover {
      background-color: ${COLORS.primaryLight};
   }
  &:active {
      background-color: ${COLORS.primaryDark};
  }
`;

export const btnSecondary = () => css`
  ${btn()}
  background-color: ${COLORS.lighterGray};
  color: ${COLORS.mediumGray};
  border-bottom: 2px solid #c8ccd0;
  transition: background-color .3s;
`;

export const btnLarge = () => css`
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 14px 28px;
  font-family: "Noto Sans", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  cursor: pointer;
`;
