import PropTypes from "prop-types";
import React from "react";
import Textarea from "react-textarea-autosize";
import styled, { css } from "styled-components";
import { layout } from "../../../main/styles/layout";

const Seller4 = ({
  design,
  agent,
  AgentInfo,
  Title,
  Subtitle,
  PrimaryInput,
  Footer
}) => {
  const { backgroundUrl } = design;
  // Dupe design object and change textColor to white for footer only (exclusive to Seller/Buyer 4)
  const footerDesign = Object.assign({}, design);
  footerDesign.textColor = "#FFFFFF";

  return (
    <LandingPageWrapper bgImg={backgroundUrl}>
      <AgentInfo agent={agent} design={design} />
      <MainContent>
        <Title
          TitleWrapper={TitleWrapper}
          TitleInput={TitleInput}
          TitleText={TitleText}
          design={design}
        />
        <PrimaryInputWrapper>
          <PrimaryInput />
        </PrimaryInputWrapper>
        <Subtitle
          SubtitleWrapper={SubtitleWrapper}
          SubtitleInput={SubtitleInput}
          SubtitleText={SubtitleText}
          design={design}
        />
      </MainContent>
      <FooterWrapper>
        <Footer
          dreNumber={agent.dreNumber}
          website={agent.website}
          design={footerDesign}
          alignment="left"
        />
      </FooterWrapper>
    </LandingPageWrapper>
  );
};

Seller4.propTypes = {
  design: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  AgentInfo: PropTypes.elementType.isRequired,
  Title: PropTypes.elementType.isRequired,
  Subtitle: PropTypes.elementType.isRequired,
  PrimaryInput: PropTypes.elementType.isRequired,
  Footer: PropTypes.elementType.isRequired
};

const LandingPageWrapper = styled.div`
  ${layout("column", "center")}
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 0;
  background-image: ${(props) => `url(${props.bgImg})`};
  @media (max-width: 1224px) {
    width: auto;
    background-color: #e4e4e4;
    background-position: -400px 125px;
    background-size: 170%;
  }
  @media (max-width: 599px) {
    padding: 0 15px;
    width: auto;
    background-color: #e4e4e4;
    background-position: -430px 280px;
    background-size: 220%;
  }
`;

const MainContent = styled.div`
  ${layout("column")}
  position: relative;
  top: 178px;
  width: 100%;
  max-width: 1200px;
  padding: 0 30px;
  @media (max-width: 599px) {
    padding: 0 15px;
  }
`;

const PrimaryInputWrapper = styled.div`
  padding-left: 24px;
  width: 100%;
  @media (max-width: 599px) {
    padding: 0;
  }
`;

const FooterWrapper = styled.div`
  ${layout("row", "center", "center")}
  position: absolute;
  bottom: 36px;
  left: 0;
  right: 0;
  width: 100%;
  @media (max-width: 599px) {
    bottom: 0;
  }
`;

// Child Components
const TitleWrapper = styled.div`
  ${layout("row", "center", "flex-start")}
  margin-bottom: 28px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  max-width: 600px;
  ${(props) =>
    props.customizable &&
    css`
      width: auto;
      border: 1px dashed #00e6c3;
      &:hover {
        border: 1px solid #00e6c3;
      }
    `}
  @media (max-width: 599px) {
    margin-bottom: 30px;
    padding: 0 8px;
  }
`;

const TitleInput = styled(Textarea)`
  font-size: 40px;
  line-height: 54px;
  min-height: 54px;
  font-weight: 400;
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  resize: none;
  overflow-wrap: break-word;
  @media (max-width: 599px) {
    text-align: center;
    font-size: 26px;
    line-height: 36px;
    font-weight: 400;
  }
`;

const TitleText = styled.h1`
  font-size: 40px;
  line-height: 54px;
  font-weight: 400;
  background-color: transparent;
  width: 100%;
  max-width: 600px;
  overflow-wrap: break-word;
  @media (max-width: 599px) {
    text-align: center;
    font-size: 26px;
    line-height: 36px;
    font-weight: 400;
  }
`;

const SubtitleWrapper = styled.div`
  ${layout("row", "center", "flex-start")}
  margin-bottom: 52px;
  padding: 20px 24px;
  box-sizing: border-box;
  width: 100%;
  max-width: 640px;
  ${(props) =>
    props.customizable &&
    css`
      width: auto;
      border: 1px dashed #00e6c3;
      &:hover {
        border: 1px solid #00e6c3;
      }
    `}
  @media (max-width: 599px) {
    margin-bottom: 34px;
    padding: 0 8px;
  }
`;

const SubtitleInput = styled(Textarea)`
  font-size: 15px;
  line-height: 20px;
  min-height: 20px;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  max-width: 600px;
  max-width: 1140px;
  resize: none;
  overflow-wrap: break-word;
  @media (max-width: 599px) {
    text-align: center;
    font-size: 13px;
    line-height: 20px;
  }
`;

const SubtitleText = styled.p`
  font-size: 15px;
  line-height: 20px;
  width: 100%;
  max-width: 600px;
  overflow-wrap: break-word;
  @media (max-width: 599px) {
    text-align: center;
    font-size: 13px;
    line-height: 20px;
  }
`;

export default Seller4;
