// FORM
export const INIT_FORM = "INIT_FORM";
export const SET_FIELD_VALUE = "SET_FIELD_VALUE";
export const SET_FIELD_TOUCHED = "SET_FIELD_TOUCHED";
export const SET_FIELD_ERROR = "SET_FIELD_ERROR";
export const CLEAR_FIELD_ERROR = "CLEAR_FIELD_ERROR";
export const SET_FORM_VALID = "SET_FORM_VALID";
export const SET_FORM_ERRORS = "SET_FORM_ERRORS";

export const CAPTURE_LEAD_REQUEST = "CAPTURE_LEAD_REQUEST";
export const CAPTURE_LEAD_SUCCESS = "CAPTURE_LEAD_SUCCESS";
export const CAPTURE_LEAD_FAILURE = "CAPTURE_LEAD_FAILURE";
export const CONVERT_LEAD_REQUEST = "CONVERT_LEAD_REQUEST";
export const CONVERT_LEAD_SUCCESS = "CONVERT_LEAD_SUCCESS";
export const CONVERT_LEAD_FAILURE = "CONVERT_LEAD_FAILURE";

// TYPEAHEAD
export const SET_ADDRESS_QUERY = "SET_ADDRESS_QUERY";
export const SET_ADDRESS = "SET_ADDRESS";
export const CLEAR_RESULTS = "CLEAR_RESULTS";
export const SET_ACTIVE_INDEX = "SET_ACTIVE_INDEX";
export const UPDATE_RESULTS_REQUEST = "UPDATE_RESULTS_REQUEST";
export const UPDATE_RESULTS_SUCCESS = "UPDATE_RESULTS_SUCCESS";
export const UPDATE_RESULTS_FAILURE = "UPDATE_RESULTS_FAILURE";
