import PropTypes from "prop-types";
import React from "react";
import { ConfirmLayout } from "./ConfirmationStyledComponents";
import ConfirmSellerArtwork from "./ConfirmSellerArtwork";

const ConfirmSellerLayout = ({ children, design }) => {
  const { accentColor, font } = design;
  return (
    <ConfirmLayout font={font}>
      {children}
      <ConfirmSellerArtwork accentColor={accentColor} />
    </ConfirmLayout>
  );
};

ConfirmSellerLayout.propTypes = {
  children: PropTypes.node.isRequired,
  design: PropTypes.object.isRequired
};

export default ConfirmSellerLayout;
