import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import COLORS from "../../main/constants/colors";
import { smallText, truncate } from "../../main/styles/typography";
import { layout } from "../../main/styles/layout";
import dropdownArrow from "../../main/images/icon-dropdown-arrow.svg";

// This Module uses Styled Components for styling.  You write CSS in a template string literal.
// You can find the docs here: https://github.com/styled-components/styled-components

class FieldsSelectInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selection: "Select an Option",
      width: 0
    };
    this.toggleOpen = this.toggleOpen.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
  }

  toggleOpen(e) {
    e.stopPropagation();
    const { width } = e.target.getBoundingClientRect();
    this.setState({
      isOpen: !this.state.isOpen,
      width
    });
  }

  handleSelection({ title, value }) {
    this.setState({
      isOpen: false,
      selection: title
    });
    this.props.onChanged(this.props.name, value);
  }

  render() {
    const { isOpen, width } = this.state;
    return (
      <SelectWrapper id={this.props.name}>
        <SelectBox
          onClick={this.toggleOpen}
          hasSelection={this.state.selection != "Select an Option"}>
          {this.state.selection}
        </SelectBox>
        <OptionsList isOpen={isOpen} width={width}>
          {this.props.options.map((o) => (
            <Option onClick={() => this.handleSelection(o)} key={o.title}>
              {o.title}
            </Option>
          ))}
        </OptionsList>
      </SelectWrapper>
    );
  }
}

FieldsSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChanged: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

// Styled Components
const SelectWrapper = styled.div`
  ${layout("column")}
  position: relative;
`;
const SelectBox = styled.div`
  ${layout("row", "center")}
  min-width: 158px;
  max-width: 360px;
  height: 42px;
  padding: 12px 32px 12px 12px;
  box-sizing: border-box;
  border: 2px solid ${COLORS.lightGray};
  border-radius: 4px;
  color: ${(props) =>
    props.hasSelection ? COLORS.mediumGray : COLORS.lightGray};
  background: url(${dropdownArrow});
  background-position: right 12px center;
  background-repeat: no-repeat;
  cursor: pointer;
  user-select: none;
`;

const OptionsList = styled.ul`
  position: absolute;
  top: 48px;
  left: 0;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-flow: column;
  width: ${(props) => props.width}px;
  max-width: 360px;
  height: auto;
  max-height: 190px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background-color: ${COLORS.lightestGray};
  list-style: none;
  z-index: 11;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Option = styled.li`
  ${layout("row", "center")}
  ${truncate()}
  ${smallText()}
  min-height: 38px;
  padding: 10px 13px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.highlightBlue};
  }
`;

export default FieldsSelectInput;
