import PropTypes from "prop-types";
import React from "react";

import ConfirmSellerLayout from "./ConfirmSellerLayout";
import PhoneTag from "./PhoneTag";
import EmailTag from "./EmailTag";

const ConfirmCma = ({ agent, leadEmail, design }) => {
  const { phoneNumber, email } = agent;

  return (
    <ConfirmSellerLayout design={design}>
      <h1>Your Report Is On Its Way</h1>
      <p>
        You should receive a link to your report by email (sent to {leadEmail})
      </p>

      <p>
        {" "}
        {/* Not sure how to format this line to make it easier to read */}
        If you have any questions or want a more accurate professional valuation
        of your home, please{" "}
        {phoneNumber ? (
          <span>
            give me a call at <PhoneTag phoneNumber={phoneNumber} /> or
          </span>
        ) : null}{" "}
        email me at <EmailTag email={email} />
      </p>
    </ConfirmSellerLayout>
  );
};

ConfirmCma.propTypes = {
  agent: PropTypes.object.isRequired,
  leadEmail: PropTypes.string.isRequired,
  design: PropTypes.object.isRequired
};

export default ConfirmCma;
