import PropTypes from "prop-types";
import React from "react";

class PageTitle extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  handleChange(e) {
    this.props.onChanged &&
      this.props.onChanged(e.target.value.replace(/\n/g, ""));
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      return e.preventDefault();
    }
  }
  render() {
    const {
      TitleWrapper,
      TitleInput,
      TitleText,
      design,
      customizable
    } = this.props;

    const { title, textColor, font } = design;
    if (customizable) {
      return (
        <TitleWrapper customizable>
          <TitleInput
            style={{ color: textColor, fontFamily: font }}
            rows={1}
            cols={title.length + 2}
            value={title}
            maxLength={100}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChange}
          />
        </TitleWrapper>
      );
    }

    return (
      <TitleWrapper>
        <TitleText style={{ color: textColor, fontFamily: font }}>
          {title}
        </TitleText>
      </TitleWrapper>
    );
  }
}

PageTitle.propTypes = {
  TitleWrapper: PropTypes.elementType.isRequired,
  TitleInput: PropTypes.elementType.isRequired,
  TitleText: PropTypes.elementType.isRequired,
  design: PropTypes.object.isRequired,
  customizable: PropTypes.bool,
  onChanged: PropTypes.func
};

export default PageTitle;
