import React from "react";
import { CopyContainer } from "./FieldsStyledComponents";

const FieldsCopyStream = () => {
  return (
    <CopyContainer>
      <h1>A Few More Details</h1>
      <p>These will allow us to personalize your listing alerts</p>
    </CopyContainer>
  );
};

export default FieldsCopyStream;
