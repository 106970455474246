import PropTypes from "prop-types";
import React from "react";
import Textarea from "react-textarea-autosize";
import styled, { css } from "styled-components";
import { layout } from "../../../main/styles/layout";
import COLORS from "../../../main/constants/colors";

const OVERLAY_COLORS = {
  dark: COLORS.black,
  light: COLORS.white
};

const getOverlay = (type) => {
  const color = OVERLAY_COLORS[type];
  if (color) {
    return <Overlay color={color} />;
  } else {
    return null;
  }
};

const Customizable = ({
  design,
  agent,
  AgentInfo,
  Title,
  Subtitle,
  PrimaryInput,
  Footer
}) => {
  const { overlay, backgroundUrl } = design;

  return (
    <LandingPageWrapper bgImg={backgroundUrl}>
      {getOverlay(overlay)}
      <AgentInfo agent={agent} design={design} />
      <ContentWrapper overlay={overlay}>
        <Title
          TitleWrapper={TitleWrapper}
          TitleInput={TitleInput}
          TitleText={TitleText}
          design={design}
        />
        <PrimaryInput />
        <Subtitle
          SubtitleWrapper={SubtitleWrapper}
          SubtitleInput={SubtitleInput}
          SubtitleText={SubtitleText}
          design={design}
        />
      </ContentWrapper>
      <FooterWrapper>
        <Footer
          dreNumber={agent.dreNumber}
          website={agent.website}
          design={design}
        />
      </FooterWrapper>
    </LandingPageWrapper>
  );
};

Customizable.propTypes = {
  design: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  AgentInfo: PropTypes.elementType.isRequired,
  Title: PropTypes.elementType.isRequired,
  Subtitle: PropTypes.elementType.isRequired,
  PrimaryInput: PropTypes.elementType.isRequired,
  Footer: PropTypes.elementType.isRequired
};

// Styled Components
const LandingPageWrapper = styled.div`
  ${layout("column", "center", "center")}
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: ${(props) => `url(${props.bgImg})`};
  @media (max-width: 599px) {
    ${layout("column", "center", "flex-start")}
    padding: 188px 15px 0 15px;
    width: auto;
  }
  @media only screen and (max-device-width: 768px) and (orientation: landscape) {
    padding: 72px 24px 10px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.color};
  opacity: 0.3;
`;

const ContentWrapper = styled.div`
  ${layout("column", "center")}
  z-index: 1;
  /* Targets the TitleInput, TitleText, SubtitleInput, SubtitleText elems */
  & textarea,
  h1,
  p {
    ${(props) => {
      if (props.overlay) {
        return props.overlay === "dark"
          ? css`
              text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
            `
          : css`
              text-shadow: 0 2px 10px rgba(255, 255, 255, 0.3);
            `;
      }
    }}
  }
`;

const TitleWrapper = styled.div`
  ${layout("row", "center", "center")}
  margin-bottom: 28px;
  padding: 24px 40px;
  box-sizing: border-box;
  width: 100%;
  ${(props) =>
    props.customizable &&
    css`
      width: auto;
      border: 1px dashed #00e6c3;
      &:hover {
        border: 1px solid #00e6c3;
      }
    `}
  @media (max-width: 599px) {
    margin-bottom: 30px;
    padding: 0 8px;
  }
  @media only screen and (max-device-width: 768px) and (orientation: landscape) {
    padding: 0 8px;
  }
`;

const TitleInput = styled(Textarea)`
  font-size: 40px;
  line-height: 47px;
  min-height: 47px;
  font-weight: 400;
  background-color: transparent;
  outline: none;
  border: none;
  min-width: 100%;
  max-width: 1140px;
  resize: none;
  text-align: center;
  overflow: hidden;
  overflow-wrap: break-word;
  @media (max-width: 599px) {
    font-size: 26px;
    line-height: 36px;
    width: 100%;
    min-height: 36px;
    font-weight: 400;
  }
`;

const TitleText = styled.h1`
  font-size: 40px;
  line-height: 47px;
  font-weight: 400;
  min-width: 100%;
  max-width: 1140px;
  text-align: center;
  overflow-wrap: break-word;
  @media (max-width: 599px) {
    font-size: 26px;
    line-height: 36px;
    font-weight: 400;
  }
`;

const SubtitleWrapper = styled.div`
  ${layout("row", "center", "center")}
  margin-bottom: 52px;
  padding: 20px 24px;
  box-sizing: border-box;
  width: 100%;
  ${(props) =>
    props.customizable &&
    css`
      width: auto;
      border: 1px dashed #00e6c3;
      &:hover {
        border: 1px solid #00e6c3;
      }
    `}
  @media (max-width: 599px) {
    margin-bottom: 34px;
    padding: 0 8px;
  }
  @media only screen and (max-device-width: 768px) and (orientation: landscape) {
    margin-bottom: 0;
    padding-top: 0;
  }
`;

const SubtitleInput = styled(Textarea)`
  font-size: 15px;
  line-height: 20px;
  min-height: 20px;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  max-width: 1140px;
  resize: none;
  text-align: center;
  overflow: hidden;
  overflow-wrap: break-word;
  @media (max-width: 599px) {
    font-size: 13px;
    line-height: 20px;
  }
`;

const SubtitleText = styled.p`
  font-size: 15px;
  line-height: 20px;
  width: 100%;
  max-width: 1140px;
  text-align: center;
  overflow-wrap: break-word;
  @media (max-width: 599px) {
    font-size: 13px;
    line-height: 20px;
  }
`;

const FooterWrapper = styled.div`
  ${layout("row", "center", "center")}
  position: absolute;
  bottom: 36px;
  left: 0;
  right: 0;
  width: 100%;
  @media (max-width: 599px) {
    bottom: 0;
  }
  @media only screen and (max-device-width: 768px) and (orientation: landscape) {
    bottom: 0;
    width: 100%;
  }
`;

export default Customizable;
