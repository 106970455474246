import PropTypes from "prop-types";
import React from "react";

import ConfirmSellerLayout from "./ConfirmSellerLayout";
import ConfirmBuyerLayout from "./ConfirmBuyerLayout";
import PhoneTag from "./PhoneTag";
import EmailTag from "./EmailTag";

const ConfirmFollowUp = ({ agent, leadEmail, leadType, design }) => {
  const { phoneNumber, email } = agent;
  switch (leadType) {
    case "seller":
      return (
        <ConfirmSellerLayout design={design}>
          <h1>I’m Assessing Your Home Value</h1>
          <p>
            For a more accurate range, I’m taking a look at what makes your home
            unique. I’ll email you soon at {leadEmail}
          </p>

          <p>
            {" "}
            {/* Not sure how to format this line to make it easier to read */}
            In the meantime, if you have any questions or would like to tell me
            more about any of your home’s unique aspects please{" "}
            {phoneNumber ? (
              <span>
                give me a call at <PhoneTag phoneNumber={phoneNumber} /> or
              </span>
            ) : null}{" "}
            email me at <EmailTag email={email} />
          </p>
        </ConfirmSellerLayout>
      );
    case "buyer":
      return (
        <ConfirmBuyerLayout design={design}>
          <h1>Thank You</h1>
          <p>
            Let’s find the perfect home for you. I’ll be looking at what’s
            available and what may be coming on the market soon. I’ll email you
            soon at {leadEmail}
          </p>

          <p>
            {" "}
            {/* Not sure how to format this line to make it easier to read */}
            In the meantime, if you have any questions or would like to tell me
            more about your unique needs, please{" "}
            {phoneNumber ? (
              <span>
                give me a call at <PhoneTag phoneNumber={phoneNumber} /> or
              </span>
            ) : null}{" "}
            email me at <EmailTag email={email} />
          </p>
        </ConfirmBuyerLayout>
      );
  }
};

ConfirmFollowUp.propTypes = {
  agent: PropTypes.object.isRequired,
  leadEmail: PropTypes.string.isRequired,
  leadType: PropTypes.string.isRequired,
  design: PropTypes.object.isRequired
};

export default ConfirmFollowUp;
