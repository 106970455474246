import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import notFoundIllustration from "../images/404.svg";
import { layout } from "../../main/styles/layout";
import COLORS from "../../main/constants/colors";

import { formatWebsiteUrl } from "../lib/url-utils";

const ArchivedPageBanner = ({ agent }) => {
  const { website } = agent;
  const url = formatWebsiteUrl(website);
  return (
    <BannerWrapper>
      <RetiredPageHeading>This Page Has Been Retired...</RetiredPageHeading>
      {website ? (
        <Suggestion>
          Maybe my <WebsiteLink href={url}>website</WebsiteLink> can help?
        </Suggestion>
      ) : null}
      <Illustration src={notFoundIllustration} />
    </BannerWrapper>
  );
};

ArchivedPageBanner.propTypes = {
  agent: PropTypes.object.isRequired
};

// Styled Components
const BannerWrapper = styled.div`
  ${layout("column", "center", "center")}
  width: 100%;
  height: 100%;
  padding: 0 16px;
  box-sizing: border-box;
`;

const RetiredPageHeading = styled.h1`
  font-family: "Circular", Helvetica, Arial, sans-serif;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  color: ${COLORS.darkGray};
  margin: 164px 0 14px 0;
  @media (max-width: 599px) {
    margin: 80px 0 14px 0;
  }
`;

const Suggestion = styled.h3`
  font-family: "Circular", Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${COLORS.mediumGray};
`;

const WebsiteLink = styled.a`
  font-family: "Circular", Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 28px;
  color: ${COLORS.primary};
  text-decoration: none;
`;

const Illustration = styled.img`
  width: 100%;
  max-width: 460px;
  margin-top: 60px;
`;

export default ArchivedPageBanner;
