import COLORS from "../constants/colors";
import { css } from "styled-components";

export const FONTFACE = "'Noto Sans', Helvetica, Arial, sans-serif";

export const title = () => css`
  font-family: ${FONTFACE};
  font-size: 48px;
  line-height: 68px;
  color: ${COLORS.darkGray};
`;

export const h1 = () => css`
  font-family: ${FONTFACE};
  font-size: 24px;
  line-height: 32px;
  color: ${COLORS.darkGray};
  @media (min-width: 600px) {
    font-size: 36px;
    line-height: 47px;
  }
`;

export const h2 = () => css`
  font-family: ${FONTFACE};
  font-size: 20px;
  line-height: 27px;
  color: ${COLORS.darkGray};
  @media (min-width: 600px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const h3 = () => css`
  font-family: ${FONTFACE};
  font-size: 20px;
  line-height: 27px;
  color: ${COLORS.darkGray};
`;

export const h4 = () => css`
  font-family: ${FONTFACE};
  font-size: 18px;
  line-height: 24px;
  color: ${COLORS.darkGray};
`;

export const h5 = () => css`
  font-family: ${FONTFACE};
  font-size: 16px;
  line-height: 21px;
  color: ${COLORS.darkGray};
`;

export const h6 = () => css`
  font-family: ${FONTFACE};
  font-size: 15px;
  line-height: 20px;
  color: ${COLORS.darkGray};
`;

export const defaultText = () => css`
  font-family: ${FONTFACE};
  font-size: 15px;
  line-height: 21px;
  color: ${COLORS.mediumGray};
`;

export const smallText = () => css`
  font-family: ${FONTFACE};
  font-size: 13px;
  line-height: 20px;
`;

export const smallerText = () => css`
  font-family: ${FONTFACE};
  font-size: 11px;
  line-height: 18px;
`;

export const tinyCaps = () => css`
  font-family: ${FONTFACE};
  font-size: 9px;
  line-height: 13px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const truncate = () => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const materialIcon = () => css`
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  user-select: none;
`;
