import PropTypes from "prop-types";
import React from "react";
import { CopyContainer } from "./FieldsStyledComponents";

const FieldsCopyCma = ({ address }) => {
  return (
    <CopyContainer>
      <h1>Found It!</h1>
      <h3>{address}</h3>
      <p>
        Please let us know where we should email your home valuation report:
      </p>
    </CopyContainer>
  );
};

FieldsCopyCma.propTypes = {
  address: PropTypes.string.isRequired
};

export default FieldsCopyCma;
