import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { layout } from "../../main/styles/layout";
import COLORS from "../../main/constants/colors";

import FieldsSelectInput from "./FieldsSelectInput";
import FieldsTextInput from "./FieldsTextInput";
import FieldsTextAreaInput from "./FieldsTextAreaInput";

// This Module uses Styled Components for styling.  You write CSS in a template string literal.
// You can find the docs here: https://github.com/styled-components/styled-components

export const timeFrameOptions = [
  { title: "1-3 Months", value: "1-3 Months" },
  { title: "3-6 Months", value: "3-6 Months" },
  { title: "6-9 Months", value: "6-9 Months" },
  { title: "Just Wondering", value: "Just Wondering" }
];

export const bedsBathsOptions = [
  { title: "1+", value: 1 },
  { title: "2+", value: 2 },
  { title: "3+", value: 3 },
  { title: "4+", value: 4 },
  { title: "5+", value: 5 },
  { title: "6+", value: 6 }
];

const DROPDOWN_OPTIONS = {
  beds: bedsBathsOptions,
  baths: bedsBathsOptions,
  time_frame: timeFrameOptions
};

export const getFieldComponent = (fieldName, fieldProps) => {
  const { onChanged } = fieldProps;
  switch (fieldName) {
    case "email":
    case "name":
    case "phone_number":
    case "sqft":
      return <FieldsTextInput {...fieldProps} />;
    case "time_frame":
    case "baths":
    case "beds":
      return (
        <FieldsSelectInput
          onChanged={onChanged}
          options={DROPDOWN_OPTIONS[fieldName]}
          name={fieldName}
        />
      );
    case "buyer_details":
      return <FieldsTextAreaInput {...fieldProps} />;
    default:
      return null;
  }
};

export const FieldInput = (props) => {
  const { field, error } = props;
  const { name, title, touched } = field;
  const errorMessage = touched && error ? <Error>{error}</Error> : null;

  const fieldComponent = getFieldComponent(name, props);

  return (
    <InputWrapper name={name}>
      <Label htmlFor={name}>{title}</Label>
      {fieldComponent}
      {errorMessage}
    </InputWrapper>
  );
};

FieldInput.propTypes = {
  field: PropTypes.object.isRequired, // TODO: Should specify shape
  error: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool.isRequired,
  onChanged: PropTypes.func.isRequired
};

// Styled Components
const InputWrapper = styled.div`
  ${layout("column", null, "space-between")}
  position: relative;
  width: ${(props) => (props.name === "buyer_details" ? "100%" : "47%")};
  margin-bottom: 40px;

  @media (max-width: 599px) {
    width: 100%;
    margin: 0 0 40px 0;
  }
`;

const Label = styled.label`
  color: ${COLORS.mediumGray};
  font-size: 15px;
  padding-bottom: 8px;
`;

const Error = styled.span`
  position: absolute;
  top: 68px;
  color: ${COLORS.errorRed};
  font-size: 12px;
  margin-top: 5px;
`;

export default FieldInput;
