import PropTypes from "prop-types";
import React from "react";

class PageSubtitle extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  handleChange(e) {
    this.props.onChanged &&
      this.props.onChanged(e.target.value.replace(/\n/g, ""));
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      return e.preventDefault();
    }
  }
  render() {
    const {
      SubtitleWrapper,
      SubtitleInput,
      SubtitleText,
      design,
      customizable
    } = this.props;

    const { subtitle, textColor, font } = design;
    if (customizable) {
      return (
        <SubtitleWrapper customizable>
          <SubtitleInput
            style={{ color: textColor, fontFamily: font }}
            rows={1}
            cols={subtitle.length + 2}
            value={subtitle}
            maxLength={280}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChange}
          />
        </SubtitleWrapper>
      );
    }

    return (
      <SubtitleWrapper>
        <SubtitleText style={{ color: textColor, fontFamily: font }}>
          {subtitle}
        </SubtitleText>
      </SubtitleWrapper>
    );
  }
}

PageSubtitle.propTypes = {
  SubtitleWrapper: PropTypes.elementType.isRequired,
  SubtitleInput: PropTypes.elementType.isRequired,
  SubtitleText: PropTypes.elementType.isRequired,
  design: PropTypes.object.isRequired,
  onChanged: PropTypes.func,
  customizable: PropTypes.bool
};

export default PageSubtitle;
