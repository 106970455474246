import PropTypes from "prop-types";
import React from "react";

import Avatar from "./Avatar";
import Initials from "./Initials";
import defaultAvatar from "../images/avatar-default.svg";

// sizes: 'xsmall', 'small', 'medium', 'large'
const AvatarOrInitials = ({ url, email, name, size, shape }) => {
  if (url) {
    return <Avatar url={url} size={size} shape={shape} />;
  } else if (email || name) {
    return <Initials email={email} name={name} size={size} />;
  } else {
    return <Avatar url={defaultAvatar} size={size} />;
  }
};

AvatarOrInitials.propTypes = {
  url: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  shape: PropTypes.string
};

AvatarOrInitials.defaultProps = {
  size: "small",
  shape: "circle"
};

export default AvatarOrInitials;
