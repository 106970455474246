import React from "react";

import MapContainer from "../containers/MapContainer";
import FieldsContainer from "../containers/FieldsContainer";

import styled from "styled-components";
import { layout, boxShadow } from "../../main/styles/layout";

const MapAndFields = () => {
  return (
    <MapAndFieldsWrapper>
      <MapContainer />
      <FieldsContainer />
    </MapAndFieldsWrapper>
  );
};

// Styled Component
const MapAndFieldsWrapper = styled.div`
  ${layout("column", "center")}
  ${boxShadow()}
  background-color: white;
  margin-top: 127px;
  @media (max-width: 599px) {
    margin-top: 0;
  }
`;

export default MapAndFields;
