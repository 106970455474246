import * as types from "../constants/actionTypes";
import { fetchResults } from "../lib/api";

import { changeFieldValue, submitPrimary } from "./formActions";

export const updateResults = () => {
  return (dispatch, getState) => {
    const {
      typeahead: { query },
      sessionId
    } = getState();

    if (query.length < 7) {
      return dispatch(clearResults());
    }

    dispatch(updateResultsRequest());
    return fetchResults(query, sessionId)
      .then((res) => res.json())
      .then((json) => dispatch(updateResultsSuccess(json)))
      .catch((err) => dispatch(updateResultsSuccess(err)));
  };
};

export const updateResultsRequest = () => {
  return {
    type: types.UPDATE_RESULTS_REQUEST
  };
};

export const updateResultsSuccess = (data) => {
  return {
    type: types.UPDATE_RESULTS_SUCCESS,
    data
  };
};

export const updateResultsFailure = (err) => {
  return {
    type: types.UPDATE_RESULTS_FAILURE,
    err
  };
};

export const clearResults = () => {
  return {
    type: types.CLEAR_RESULTS
  };
};

export const setAddress = (address) => {
  return {
    type: types.SET_ADDRESS,
    address
  };
};

export const selectResult = (result) => {
  return (dispatch) => {
    return dispatch(setResult(result)).then(() =>
      dispatch(submitPrimary("address"))
    );
  };
};

export const setResult = (result) => {
  return (dispatch) => {
    dispatch(setAddress(result));
    dispatch(changeFieldValue("address", result.value));
    dispatch(clearResults());
    return Promise.resolve();
  };
};

export const setActiveIndex = (index) => {
  return {
    type: types.SET_ACTIVE_INDEX,
    index
  };
};

export const traverseUp = () => {
  return (dispatch, getState) => {
    const { activeIndex } = getState().typeahead;
    if (activeIndex > -1) {
      dispatch(setActiveIndex(activeIndex - 1));
    }
    return Promise.resolve();
  };
};

export const traverseDown = () => {
  return (dispatch, getState) => {
    const { results, activeIndex } = getState().typeahead;
    if (activeIndex < results.length - 1) {
      dispatch(setActiveIndex(activeIndex + 1));
    }
    return Promise.resolve();
  };
};

export const setAddressQuery = (query) => {
  return {
    type: types.SET_ADDRESS_QUERY,
    query
  };
};
