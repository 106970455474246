import React from "react";
import { Route, IndexRedirect } from "react-router";

import App from "./containers/App";
// Slide up pages: Map + Fields, Confirmation
import MapAndFields from "./components/MapAndFields";
import ConfirmationContainer from "./containers/ConfirmationContainer";
import BottomPanel from "./components/BottomPanel";

const routes = (
  <Route path=":guid" component={App}>
    {/* The following IndexRedirects are helpful for development */}
    {/* <IndexRedirect to='fields'/> */}
    {/* <IndexRedirect to='confirm'/> */}
    <Route component={BottomPanel}>
      <Route path="fields" component={MapAndFields}></Route>
      {/* Possibly another route to say that your deliverable is being created */}
      {/* <Route path='delivery' component={Delivery}></Route> */}
      <Route path="confirm" component={ConfirmationContainer}></Route>
    </Route>
  </Route>
);

export default routes;
