export const fbPixel = (event) => {
  if (window.fbq) {
    window.fbq("track", event);
  }
};

export const contentViewedEvent = () => {
  fbPixel("ViewContent");
};

export const leadSubmittedEvent = () => {
  fbPixel("Lead");
};
