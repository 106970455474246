import { combineReducers } from "redux";
import * as types from "../constants/actionTypes";

export const field = (state = {}, action) => {
  switch (action.type) {
    case types.SET_FIELD_VALUE:
      if (state.name === action.name) {
        return { ...state, value: action.value };
      } else {
        return state;
      }
    case types.SET_FIELD_TOUCHED:
      if (state.name === action.name) {
        return { ...state, touched: action.touched };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export const fields = (state = [], action) => {
  return state.map((f) => field(f, action));
};

export const errors = (state = {}, action) => {
  switch (action.type) {
    case types.SET_FORM_ERRORS:
      return { ...action.errors };
    case types.SET_FIELD_ERROR:
      return { ...state, [action.name]: action.err };
    case types.CLEAR_FIELD_ERROR: {
      // eslint-disable-next-line no-unused-vars
      const { [action.name]: deletedItem, ...rest } = state;
      return rest;
    }
    default:
      return state;
  }
};

export const valid = (state = false, action) => {
  switch (action.type) {
    case types.SET_FORM_VALID:
      return action.valid;
    default:
      return state;
  }
};

export const fieldsSubmitDisabled = (state = false, action) => {
  switch (action.type) {
    case types.CAPTURE_LEAD_REQUEST:
      return true;
    case types.CAPTURE_LEAD_SUCCESS:
    case types.CAPTURE_LEAD_FAILURE:
      return false;
    default:
      return state;
  }
};

const form = combineReducers({
  fields,
  errors,
  valid,
  fieldsSubmitDisabled
});
export default form;
