import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router";

import ModalCloser from "../../main/components/ModalCloser";

import { layout } from "../../main/styles/layout";
import { h5 } from "../../main/styles/typography";
import anchorPoint from "../../main/images/tooltip-anchor.svg";
import COLORS from "../../main/constants/colors";

const AttractFooterTooltip = ({ visible, closeTooltip }) => (
  <TooltipWrapper visible={visible}>
    <Tooltip>
      <Title>Your Info</Title>
      <Tip>
        You can edit this information by going to{" "}
        <Link to="/account-settings" target="_blank">
          your profile
        </Link>
      </Tip>
    </Tooltip>
    <AnchorPoint />
    <ModalCloser onClick={closeTooltip} />
  </TooltipWrapper>
);

// Styled Components
const TooltipWrapper = styled.div`
  ${layout("column", "center")}
  ${(props) =>
    !props.visible &&
    css`
      display: none;
    `}
  position: absolute;
  bottom: 94px;
  font-family: "Noto Sans", Helvetica, Arial, sans-serif;
`;

const Tooltip = styled.div`
  position: relative;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  width: 260px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${COLORS.white};
`;

const Title = styled.h5`
  ${h5}
  padding: 10px 15px;
  box-sizing: border-box;
  border-bottom: 1px solid ${COLORS.lighterGray};
`;

const Tip = styled.p`
  padding: 10px 15px 20px 15px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 24px;
  & a {
    text-decoration: none;
    color: ${COLORS.primary};
  }
`;

const AnchorPoint = styled.div`
  width: 26px;
  height: 16px;
  background-image: url(${anchorPoint});
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
  margin-right: 12px;
  transform: scaleY(-1);
`;

export default AttractFooterTooltip;
