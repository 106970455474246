import COLORS from "../constants/colors";
import { css } from "styled-components";

// For one-line flexbox layouts
export const layout = (
  direction = "row",
  alignItems = "",
  justifyContent = ""
) => css`
  display: flex;
  flex-flow: ${direction};
  align-items: ${alignItems};
  justify-content: ${justifyContent};
`;

// The boxShadow util belongs here bc shadows affect depth perception (the Z dimension).
// Any utils affecting the layout of the UI (including Z-space) go in this file.
export const boxShadow = (
  offsetX = 0,
  offsetY = 2,
  blurRadius = 10,
  color = "rgba(0, 0, 0, .15)"
) => css`
  box-shadow: ${offsetX}px ${offsetY}px ${blurRadius}px ${color};
`;

// Table Layout Convenience Methods - matches table styling in our Design Style Guide
export const tableContainer = () => css`
  width: 100%;
  border: 2px solid ${COLORS.lighterGray};
  border-radius: 5px;
  overflow: hidden;
`;

export const table = () => css`
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
  line-height: 18px;
  & thead {
    & tr {
      height: 48px;
      background-color: ${COLORS.lightestGray};
      border-bottom: 1px solid ${COLORS.lighterGray};
      & th {
        vertical-align: middle;
        padding: 0 18px;
        text-align: left;
      }
    }
  }
  & tbody {
    & tr {
      height: 54px;
      border-bottom: 1px solid ${COLORS.lighterGray};
      &:hover {
        background-color: ${COLORS.highlightBlue};
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
  & td {
    vertical-align: middle;
    padding: 0 18px;
    text-align: ${(props) => (props.alignText === "right" ? "right" : "left")};
  }
`;
