import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { browserHistory } from "react-router";
import { routerMiddleware } from "react-router-redux";
import rootReducer from "../reducers";

const middlewares = [thunk, routerMiddleware(browserHistory)];

export default function configureStore(initialState) {
  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middlewares))
  );
}
