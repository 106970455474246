import PropTypes from "prop-types";
import React from "react";
import { shadeColor } from "../lib/viewHelpers";
import styled from "styled-components";
import { boxShadow } from "../../main/styles/layout";
import COLORS from "../../main/constants/colors";

const TypeaheadResults = ({
  design,
  results,
  activeIndex,
  onResultSelected,
  onResultMouseEntered,
  onResultMouseLeft
}) => {
  if (!results.length) {
    return null;
  }

  const resultItems = results.map((result, i) => (
    <Result
      key={i}
      style={{
        backgroundColor:
          i === activeIndex ? shadeColor(design.accentColor, 0.9) : null
      }}
      onMouseDown={() => onResultSelected(result)}
      onMouseEnter={() => onResultMouseEntered(i)}
      onMouseLeave={() => onResultMouseLeft(i)}>
      {result.value}
    </Result>
  ));

  return <TypeAheadResults font={design.font}>{resultItems}</TypeAheadResults>;
};

TypeaheadResults.propTypes = {
  design: PropTypes.object.isRequired,
  results: PropTypes.array.isRequired,
  activeIndex: PropTypes.number.isRequired,
  onResultSelected: PropTypes.func.isRequired,
  onResultMouseEntered: PropTypes.func.isRequired,
  onResultMouseLeft: PropTypes.func.isRequired
};

// Styled Components
const TypeAheadResults = styled.ul`
  position: absolute;
  top: 56px;
  width: 500px;
  background-color: ${COLORS.white};
  border-top: 1px solid ${COLORS.lightGray};
  ${boxShadow()}
  z-index: 2;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  font-family: ${(props) => props.font};
  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

const Result = styled.li`
  padding: 16px;
  font-size: 15px;
  line-height: 18px;
  color: ${COLORS.mediumGray};
  cursor: pointer;
`;

export default TypeaheadResults;
