import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { boxShadow } from "../../main/styles/layout";

import ConfirmCma from "./ConfirmCma";
import ConfirmAvm from "./ConfirmAvm";
import ConfirmStream from "./ConfirmStream";
import ConfirmFollowUp from "./ConfirmFollowUp";

const getConfirmComponent = (
  leadType,
  confirmType,
  agent,
  leadEmail,
  design
) => {
  switch (confirmType) {
    case "cma":
      return <ConfirmCma agent={agent} leadEmail={leadEmail} design={design} />;
    case "avm":
      return <ConfirmAvm agent={agent} leadEmail={leadEmail} design={design} />;
    case "stream":
      return (
        <ConfirmStream agent={agent} leadEmail={leadEmail} design={design} />
      );
    case "followUp":
      return (
        <ConfirmFollowUp
          agent={agent}
          leadEmail={leadEmail}
          leadType={leadType}
          design={design}
        />
      );
  }
};

const Confirmation = ({ leadType, confirmType, agent, leadEmail, design }) => {
  return (
    <ConfirmationWrapper>
      {getConfirmComponent(leadType, confirmType, agent, leadEmail, design)}
    </ConfirmationWrapper>
  );
};

Confirmation.propTypes = {
  leadType: PropTypes.string.isRequired,
  confirmType: PropTypes.string.isRequired,
  agent: PropTypes.object.isRequired,
  leadEmail: PropTypes.string.isRequired,
  design: PropTypes.object.isRequired
};

// Styled Component
const ConfirmationWrapper = styled.div`
  ${boxShadow()}
  margin-top: 127px;
  background-color: white;
  height: 100vh;
  @media (max-width: 599px) {
    margin-top: 0;
  }
`;

export default Confirmation;
