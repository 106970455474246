import { combineReducers } from "redux";
import * as types from "../constants/actionTypes";

export const results = (state = [], action) => {
  switch (action.type) {
    case types.UPDATE_RESULTS_SUCCESS:
      return action.data.suggestions;
    case types.CLEAR_RESULTS:
      return [];
    default:
      return state;
  }
};

export const activeIndex = (state = -1, action) => {
  switch (action.type) {
    case types.SET_ACTIVE_INDEX:
      return action.index;
    default:
      return state;
  }
};

export const query = (state = "", action) => {
  switch (action.type) {
    case types.SET_ADDRESS_QUERY:
      return action.query;
    default:
      return state;
  }
};

const typeahead = combineReducers({
  results,
  activeIndex,
  query
});

export default typeahead;
