import PropTypes from "prop-types";
import React, { Component } from "react";

import KEYS from "../../main/constants/keys";

import {
  PrimaryInputWrapper,
  Input,
  SubmitButton,
  CustomizeSubmitButton
} from "./PrimaryAddress";

import emailIcon from "../images/icon-email-search.svg";

class PrimaryEmail extends Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleKeyDown(e) {
    switch (KEYS[e.keyCode]) {
      case "enter":
        return this.handleSubmit();
      default:
        return;
    }
  }
  handleSubmit() {
    if (this.props.inputValue) {
      return this.props.submitPrimary("email");
    }
  }
  render() {
    const { design, inputValue, customizable, changeFieldValue } = this.props;

    if (customizable) {
      return (
        <PrimaryInputWrapper>
          <Input
            disabled
            type="text"
            font={design.font}
            placeholder={"Enter your email address"}
          />
          <CustomizeSubmitButton
            // NOTE: Hackiness to make Customize Mobile view work consistently
            style={{
              backgroundColor: design.accentColor,
              backgroundImage: `url(${emailIcon})`
            }}
          />
        </PrimaryInputWrapper>
      );
    }

    return (
      <PrimaryInputWrapper>
        <Input
          autoFocus
          type="text"
          value={inputValue}
          font={design.font}
          placeholder={"Enter your email address"}
          onChange={(e) => changeFieldValue("email", e.target.value)}
          onKeyDown={this.handleKeyDown}
        />
        <SubmitButton
          bgColor={design.accentColor}
          bgImg={emailIcon}
          onClick={this.handleSubmit}
        />
      </PrimaryInputWrapper>
    );
  }
}

PrimaryEmail.propTypes = {
  design: PropTypes.object.isRequired,
  customizable: PropTypes.bool,
  inputValue: PropTypes.string,
  changeFieldValue: PropTypes.func,
  submitPrimary: PropTypes.func
};

export default PrimaryEmail;
