import PropTypes from "prop-types";
import React from "react";

import FieldInput from "./FieldInput";
import FieldsCopyCma from "./FieldsCopyCma";
import FieldsCopyStream from "./FieldsCopyStream";
import FieldsCopyFollowUp from "./FieldsCopyFollowUp";

import {
  FieldsWrapper,
  FieldInputs,
  ButtonWrapper,
  Button
} from "./FieldsStyledComponents";

import { shadeColor } from "../lib/viewHelpers";

export const getCopyComponent = (copyType, copyProps) => {
  switch (copyType) {
    case "cma":
      return <FieldsCopyCma {...copyProps} />;
    case "avm":
      return <FieldsCopyCma {...copyProps} />;
    case "stream":
      return <FieldsCopyStream {...copyProps} />;
    case "followUp":
      return <FieldsCopyFollowUp {...copyProps} />;
    default:
      return <FieldsCopyFollowUp leadType="buyer" />;
  }
};

const Fields = ({
  leadType,
  design,
  form,
  onFieldChanged,
  onSubmitted,
  copyType,
  copyProps
}) => {
  const { fields, errors } = form;
  const fieldsCopy = getCopyComponent(copyType, copyProps);
  const { font, accentColor } = design;
  const isMobile = window.innerWidth && window.innerWidth < 420;
  const fieldInputs = fields
    ? fields.map((field, i) => {
        return (
          <FieldInput
            key={i}
            field={field}
            font={font}
            error={errors[field.name] || ""}
            autoFocus={i === 0 && !isMobile ? true : false}
            onChanged={onFieldChanged}
          />
        );
      })
    : "Error: No fields loaded";

  return (
    <FieldsWrapper font={font} leadType={leadType}>
      {fieldsCopy}
      <FieldInputs fieldCount={fieldInputs.length}>{fieldInputs}</FieldInputs>
      <ButtonWrapper>
        <Button
          disabled={form.fieldsSubmitDisabled}
          color={accentColor}
          accentColor={shadeColor(accentColor, -0.1)}
          onClick={onSubmitted}>
          Submit
        </Button>
      </ButtonWrapper>
    </FieldsWrapper>
  );
};

Fields.propTypes = {
  leadType: PropTypes.string.isRequired,
  design: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onFieldChanged: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  copyType: PropTypes.string.isRequired,
  copyProps: PropTypes.object.isRequired
};

export default Fields;
