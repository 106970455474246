import PropTypes from "prop-types";
import React from "react";
import Textarea from "react-textarea-autosize";
import styled, { css } from "styled-components";
import { layout } from "../../../main/styles/layout";

const Buyer13 = ({
  design,
  agent,
  AgentInfo,
  Title,
  Subtitle,
  PrimaryInput,
  Footer
}) => {
  const { backgroundUrl } = design;

  return (
    <LandingPageWrapper>
      <AgentInfoWrapper>
        <AgentInfo agent={agent} design={design} />
      </AgentInfoWrapper>
      <ContentWrapper>
        <MainContent>
          <Title
            TitleWrapper={TitleWrapper}
            TitleInput={TitleInput}
            TitleText={TitleText}
            design={design}
          />
          <Subtitle
            SubtitleWrapper={SubtitleWrapper}
            SubtitleInput={SubtitleInput}
            SubtitleText={SubtitleText}
            design={design}
          />
          <PrimaryInput />
        </MainContent>
        <Illustration bgImg={backgroundUrl} />
        <FooterWrapper>
          <Footer
            dreNumber={agent.dreNumber}
            website={agent.website}
            design={design}
          />
        </FooterWrapper>
      </ContentWrapper>
    </LandingPageWrapper>
  );
};

Buyer13.propTypes = {
  design: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  AgentInfo: PropTypes.elementType.isRequired,
  Title: PropTypes.elementType.isRequired,
  Subtitle: PropTypes.elementType.isRequired,
  PrimaryInput: PropTypes.elementType.isRequired,
  Footer: PropTypes.elementType.isRequired
};

// Styled Components
const LandingPageWrapper = styled.div`
  ${layout("column", "center")}
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #efd7bf;
`;

const AgentInfoWrapper = styled.div`
  z-index: 1;
`;

const ContentWrapper = styled.div`
  ${layout("column", "center")}
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 0;
  background-color: #fcf8ef;
`;

const MainContent = styled.div`
  ${layout("column", "center")}
  width: 100%;
  max-width: 1200px;
  height: auto;
  padding: 144px 30px 0 30px;
  box-sizing: border-box;
  @media (max-width: 600px) {
    padding: 188px 15px 0 15px;
  }
`;

const Illustration = styled.div`
  height: 178px;
  width: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-image: ${(props) => `url(${props.bgImg})`};
  @media (max-width: 599px) {
    background-size: cover;
    background-position: center;
  }
`;

const FooterWrapper = styled.div`
  ${layout("row", "center", "center")}
  height: 80px;
  width: 100%;
  background-color: #efd7bf;
  border-top: 2px solid #6c5f53;
`;

// Styled Child Components passed in as props
const TitleWrapper = styled.div`
  ${layout("row", "center", "center")}
  width: 100%;
  margin-bottom: 10px;
  padding: 0 24px;
  box-sizing: border-box;
  ${(props) =>
    props.customizable &&
    css`
      width: auto;
      border: 1px dashed #00e6c3;
      &:hover {
        border: 1px solid #00e6c3;
      }
    `}
  @media (max-width: 599px) {
    margin-bottom: 30px;
    padding: 0;
  }
`;

const TitleInput = styled(Textarea)`
  font-size: 40px;
  line-height: 52px;
  min-height: 52px;
  width: 100%;
  max-width: 1140px;
  font-weight: 600;
  border: none;
  outline: none;
  resize: none;
  text-align: center;
  background: transparent;
  overflow: hidden;
  overflow-wrap: break-word;
  @media (max-width: 599px) {
    font-size: 26px;
    line-height: 36px;
    font-weight: 400;
  }
`;

const TitleText = styled.h1`
  font-size: 40px;
  line-height: 52px;
  width: 100%;
  max-width: 1140px;
  font-weight: 600;
  text-align: center;
  overflow-wrap: break-word;
  @media (max-width: 599px) {
    font-size: 26px;
    line-height: 36px;
    font-weight: 400;
  }
`;

const SubtitleWrapper = styled.div`
  ${layout("row", "center", "center")}
  width: 100%;
  margin-bottom: 33px;
  padding: 8px 24px;
  box-sizing: border-box;
  ${(props) =>
    props.customizable &&
    css`
      width: auto;
      border: 1px dashed #00e6c3;
      &:hover {
        border: 1px solid #00e6c3;
      }
    `}
  @media (max-width: 599px) {
    margin-bottom: 34px;
    padding: 0;
  }
`;

const SubtitleInput = styled(Textarea)`
  font-size: 15px;
  line-height: 20px;
  min-height: 20px;
  height: 20px;
  width: 100%;
  max-width: 1140px;
  border: none;
  outline: none;
  resize: none;
  text-align: center;
  background: transparent;
  overflow: hidden;
  overflow-wrap: break-word;
  @media (max-width: 599px) {
    font-size: 13px;
    line-height: 20px;
  }
`;

const SubtitleText = styled.h1`
  font-size: 15px;
  line-height: 20px;
  width: 100%;
  max-width: 1140px;
  text-align: center;
  overflow-wrap: break-word;
  @media (max-width: 599px) {
    font-size: 13px;
    line-height: 20px;
  }
`;

export default Buyer13;
