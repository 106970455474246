import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Fields from "../components/Fields";

import { changeFieldValue, submitForm } from "../actions/formActions";

import { getAddress } from "../store/selectors";

export const getFilteredFormFields = (state) => {
  switch (state.page.leadType) {
    case "seller":
      return state.form.fields.filter((f) => f.name !== "address");
    case "buyer":
      return state.form.fields.filter((f) => f.name !== "email");
  }
};

export const getCopyProps = (state, copyType, leadType) => {
  switch (copyType) {
    case "cma":
      return { address: getAddress(state, "address").value };
    case "avm":
      return { address: getAddress(state, "address").value };
    case "stream":
      return {};
    case "followUp":
      if (leadType === "seller") {
        return { leadType, address: getAddress(state, "address").value };
      }
      return { leadType };
  }
};

export const mapStateToProps = (state) => {
  const { form } = state;
  const { design, leadType, deliverables } = state.page;
  const copyType = deliverables.length ? deliverables[0].name : "followUp";
  return {
    leadType,
    design,
    copyType,
    copyProps: getCopyProps(state, copyType, leadType),
    form: { ...form, fields: getFilteredFormFields(state) }
  };
};

export const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onFieldChanged: changeFieldValue,
      onSubmitted: submitForm
    },
    dispatch
  );
};

const FieldsContainer = connect(mapStateToProps, mapDispatchToProps)(Fields);

export default FieldsContainer;
