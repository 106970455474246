import React from "react";
import styled from "styled-components";

const Logo = styled.div`
  display: block;
  width: 100px;
  margin: 4px auto 0;
  svg {
    width: 100px;
  }
  @media only screen and (max-device-width: 768px) and (orientation: landscape) {
    margin: 0 0 0 auto;
  }
`;

// Original svg image file in ../images/equal-housing-logo.svg
const EqualHousingOpportunityLogo = ({ color }) => (
  <Logo>
    <svg
      width="154px"
      height="29px"
      viewBox="0 0 154 29"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>Fair Housing Logo</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="Fair-Housing-Logo" fill={color} fillRule="nonzero">
          <path
            d="M0,10 L0,14.69 L2.27,14.69 L2.27,28.78 L38.16,28.78 L38.16,14.69 L40.89,14.69 L40.89,10 L20.29,0 L0,10 Z M33.77,24.53 L6.66,24.53 L6.66,11.66 L20.29,4.66 L33.77,11.66 L33.77,24.53 Z"
            id="Shape"
          />
          <rect
            id="Rectangle-path"
            x="13.93"
            y="11.21"
            width="12.57"
            height="3.54"
          />
          <rect
            id="Rectangle-path"
            x="13.93"
            y="17.72"
            width="12.57"
            height="3.54"
          />
          <polygon
            id="Shape"
            points="55.83 14.05 52.14 14.05 52.14 11.98 55.5 11.98 55.5 10.54 52.14 10.54 52.14 8.65 55.83 8.65 55.83 7.16 50.52 7.16 50.52 15.55 55.83 15.55"
          />
          <path
            d="M64,15 L64.74,15.75 L65.56,14.93 L64.76,14.15 C65.2980682,13.2923458 65.5630875,12.2915491 65.52,11.28 C65.5302081,10.6398164 65.4252036,10.0030147 65.21,9.4 C65.0283375,8.87414797 64.7310403,8.3957387 64.34,8 C64.0057924,7.66223255 63.608191,7.39376667 63.17,7.21 C62.306,6.85 61.334,6.85 60.47,7.21 C59.5242874,7.60444058 58.7951357,8.38827859 58.47,9.36 C58.2594529,9.9642098 58.1545856,10.6001795 58.16,11.24 C58.1505075,11.9356897 58.255232,12.6282229 58.47,13.29 C58.6317908,13.8129241 58.9163843,14.2895325 59.3,14.68 C59.6290464,15.0119329 60.0242826,15.2708807 60.46,15.44 C60.8895158,15.6101396 61.3480414,15.6950518 61.81,15.69 C62.5986035,15.7260363 63.3744816,15.4815816 64,15 Z M60.46,13.53 C60.0330666,12.8610921 59.8364194,12.0709914 59.9,11.28 C59.8517472,10.5392757 60.0523683,9.80366494 60.47,9.19 C60.7951652,8.7417662 61.3162494,8.47750202 61.87,8.48 C62.4225908,8.48235445 62.9416052,8.74556892 63.27,9.19 C63.6876317,9.80366494 63.8882528,10.5392757 63.84,11.28 C63.8402262,11.8646971 63.7456664,12.4455645 63.56,13 L62,11.43 L61.18,12.24 L62.9,14 C62.5776551,14.2028596 62.2003026,14.3006917 61.82,14.28 C61.254513,14.2800708 60.7260074,13.9989508 60.41,13.53 L60.46,13.53 Z"
            id="Shape"
          />
          <path
            d="M73.43,15 C74.0254352,14.4449582 74.3409856,13.6524131 74.29,12.84 L74.29,7.15 L72.67,7.15 L72.67,12.77 C72.6910993,13.1667472 72.5461869,13.5543879 72.27,13.84 C71.9556643,14.1209983 71.5404217,14.2618197 71.12,14.23 C70.7008937,14.2611174 70.2877792,14.1161649 69.98,13.83 C69.7099534,13.5371554 69.5693584,13.1478153 69.59,12.75 L69.59,7.15 L68,7.15 L68,12.82 C67.9326511,13.6328575 68.2336352,14.4330348 68.82,15 C70.1942829,15.999059 72.0557171,15.999059 73.43,15 Z"
            id="Shape"
          />
          <path
            d="M81.74,15.56 L83.41,15.56 L80.41,7.15 L79,7.15 L76,15.56 L77.61,15.56 L78.09,14.18 L81.29,14.18 L81.74,15.56 Z M78.51,12.83 L79.65,9.56 L80.78,12.83 L78.51,12.83 Z"
            id="Shape"
          />
          <polygon
            id="Shape"
            points="87.25 7.16 85.63 7.16 85.63 15.55 90.72 15.55 90.72 14.05 87.25 14.05"
          />
          <polygon
            id="Shape"
            points="101.16 15.55 102.78 15.55 102.78 7.16 101.16 7.16 101.16 10.54 98.28 10.54 98.28 7.16 96.66 7.16 96.66 15.55 98.28 15.55 98.28 11.92 101.16 11.92"
          />
          <path
            d="M111.58,14.72 C111.960034,14.326779 112.244086,13.8510768 112.41,13.33 C112.621201,12.6676168 112.722541,11.975126 112.71,11.28 C112.718431,10.6407751 112.616954,10.004855 112.41,9.4 C112.23976,8.87792656 111.956347,8.39988126 111.58,8 C111.247593,7.66468979 110.853766,7.39647975 110.42,7.21 C109.988431,7.05096788 109.529485,6.97957634 109.07,7 C108.606269,6.99671454 108.146796,7.08860928 107.72,7.27 C107.281809,7.45376667 106.884208,7.72223255 106.55,8.06 C106.178612,8.44826372 105.895487,8.91217901 105.72,9.42 C105.513046,10.024855 105.411569,10.6607751 105.42,11.3 C105.407459,11.995126 105.508799,12.6876168 105.72,13.35 C105.885914,13.8710768 106.169966,14.346779 106.55,14.74 C106.880886,15.0696734 107.275619,15.3282918 107.71,15.5 C108.142989,15.6701891 108.604793,15.7550795 109.07,15.75 C109.531959,15.7550518 109.990484,15.6701396 110.42,15.5 C110.857497,15.3249991 111.252874,15.0591425 111.58,14.72 Z M109.07,14.25 C108.506994,14.2584263 107.975924,13.9891514 107.65,13.53 C107.227247,12.8596046 107.034189,12.0698221 107.1,11.28 C107.054096,10.5409398 107.250724,9.80709807 107.66,9.19 C107.99049,8.74274202 108.513886,8.47918835 109.07,8.48 C109.619835,8.48232973 110.135822,8.74589131 110.46,9.19 C110.877632,9.80366494 111.078253,10.5392757 111.03,11.28 C111.096568,12.0762033 110.903617,12.8725642 110.48,13.55 C110.148456,13.9947556 109.624713,14.2547698 109.07,14.25 Z"
            id="Shape"
          />
          <path
            d="M121.5,7.15 L119.88,7.15 L119.88,12.77 C119.901099,13.1667472 119.756187,13.5543879 119.48,13.84 C119.164493,14.1189849 118.750127,14.2595093 118.33,14.23 C117.907836,14.2615061 117.491487,14.116689 117.18,13.83 C116.909953,13.5371554 116.769358,13.1478153 116.79,12.75 L116.79,7.15 L115.17,7.15 L115.17,12.82 C115.105219,13.6344424 115.409968,14.4348672 116,15 C117.370117,16.0005291 119.229883,16.0005291 120.6,15 C121.201536,14.4484834 121.521342,13.6544816 121.47,12.84 L121.5,7.15 Z"
            id="Shape"
          />
          <path
            d="M127.93,10.61 C127.319011,10.4190326 126.721084,10.1885466 126.14,9.92 C125.922059,9.81462224 125.785383,9.59203562 125.79,9.35 C125.787629,9.10273342 125.902646,8.86898945 126.1,8.72 C126.3747,8.53883785 126.70194,8.45438882 127.03,8.48 C127.765231,8.44291252 128.420051,8.94142045 128.58,9.66 L130.15,9.27 C130.047273,8.57309708 129.659744,7.95015341 129.08,7.55 C128.457675,7.16184726 127.732846,6.97018572 127,7 C126.245551,6.95768152 125.503922,7.20847382 124.93,7.7 C124.442887,8.12169752 124.165111,8.73573012 124.17,9.38 C124.150879,9.9127872 124.345268,10.431158 124.71,10.82 C125.237968,11.3017315 125.876971,11.6452817 126.57,11.82 C127.190743,11.9765139 127.788927,12.2117546 128.35,12.52 C128.60559,12.6909097 128.753444,12.9828261 128.74,13.29 C128.751739,13.5911343 128.593255,13.8733143 128.33,14.02 C127.994625,14.1959222 127.618248,14.2787943 127.24,14.26 C126.80774,14.3030062 126.379785,14.144368 126.08,13.83 C125.799593,13.4039098 125.624764,12.9171308 125.57,12.41 L123.87,12.78 C123.942178,13.6392081 124.347774,14.4360419 125,15 C125.653642,15.5217243 126.474547,15.7882519 127.31,15.75 C128.121433,15.7934391 128.919972,15.533201 129.55,15.02 C130.098282,14.5643179 130.407773,13.8827016 130.39,13.17 C130.419195,12.6230234 130.236548,12.085824 129.88,11.67 C129.321095,11.1700883 128.65347,10.8071742 127.93,10.61 Z"
            id="Shape"
          />
          <rect
            id="Rectangle-path"
            x="132.87"
            y="7.15"
            width="1.62"
            height="8.4"
          />
          <polygon
            id="Shape"
            points="142.39 12.66 142.37 12.66 139.29 7.16 137.67 7.16 137.67 15.55 139.29 15.55 139.29 10.05 139.32 10.05 142.43 15.55 144.02 15.55 144.02 7.16 142.39 7.16"
          />
          <path
            d="M150.43,12.54 L152,12.54 C152.047106,12.9992586 151.885687,13.4554243 151.560208,13.7828402 C151.23473,14.110256 150.77953,14.2743793 150.32,14.23 C149.754732,14.2379088 149.222512,13.9643028 148.9,13.5 C148.476709,12.8184319 148.280631,12.0201161 148.34,11.22 C148.301808,10.5069502 148.506248,9.80198598 148.92,9.22 C149.248395,8.77556892 149.767409,8.51235445 150.32,8.51 C151.19,8.51 151.73,9.05 151.93,10.13 L153.55,9.86 C153.465527,9.05233998 153.079927,8.30613347 152.47,7.77 C151.868248,7.2512761 151.094123,6.97658669 150.3,7 C149.325859,6.99051647 148.394174,7.39812861 147.74,8.12 C146.983974,8.97766044 146.595997,10.0984834 146.66,11.24 C146.559019,12.4551556 146.915669,13.664199 147.66,14.63 C148.326229,15.3762283 149.290318,15.7867908 150.29,15.75 C151.161507,15.7847668 152.00734,15.4507898 152.62,14.83 C153.245421,14.1404527 153.569435,13.2296148 153.52,12.3 L153.52,11.16 L150.41,11.16 L150.43,12.54 Z"
            id="Shape"
          />
          <path
            d="M56.2,20.54 C55.8557876,20.2003844 55.448018,19.9319361 55,19.75 C54.5726295,19.5704772 54.113543,19.4786599 53.65,19.48 C53.1862692,19.4767145 52.7267955,19.5686093 52.3,19.75 C51.861809,19.9337667 51.4642076,20.2022325 51.13,20.54 C50.7586118,20.9282637 50.4754871,21.392179 50.3,21.9 C50.0930459,22.504855 49.9915693,23.1407751 50,23.78 C49.9928586,24.4583886 50.0941347,25.1335624 50.3,25.78 C50.4659138,26.3010768 50.7499662,26.776779 51.13,27.17 C51.4608861,27.4996734 51.8556195,27.7582918 52.29,27.93 C52.7229889,28.1001891 53.1847926,28.1850795 53.65,28.18 C54.1073906,28.2074394 54.5657775,28.1463211 55,28 C55.4357174,27.8308807 55.8309536,27.5719329 56.16,27.24 C56.5462937,26.8420662 56.8339815,26.3591616 57,25.83 C57.2058653,25.1835624 57.3071414,24.5083886 57.3,23.83 C57.3142469,23.1740323 57.2126915,22.5206923 57,21.9 C56.8333648,21.394806 56.5605961,20.9310993 56.2,20.54 Z M55.1,26.05 C54.7684557,26.4947556 54.2447129,26.7547698 53.69,26.75 C53.1214131,26.7516286 52.5892056,26.4705331 52.27,26 C51.847247,25.3296046 51.6541891,24.5398221 51.72,23.75 C51.6740962,23.0109398 51.8707237,22.2770981 52.28,21.66 C52.6208762,21.2315432 53.1426327,20.9873168 53.69,21 C54.2398351,21.0023297 54.7558218,21.2658913 55.08,21.71 C55.4976317,22.3236649 55.6982528,23.0592757 55.65,23.8 C55.7121964,24.5895344 55.5194133,25.3781924 55.1,26.05 Z"
            id="Shape"
          />
          <path
            d="M64.58,19.65 L61.17,19.65 L61.17,28.06 L62.79,28.06 L62.79,25 L64.58,25 C65.3531015,25.0425688 66.1127239,24.7845839 66.7,24.28 C67.241482,23.7611228 67.5237224,23.0280309 67.47,22.28 C67.5037619,21.5438619 67.2110294,20.8303263 66.67,20.33 C66.0844351,19.8471126 65.3376027,19.6041241 64.58,19.65 Z M65.45,23.18 C65.2040332,23.3946269 64.8863217,23.5088603 64.56,23.5 L62.79,23.5 L62.79,21.15 L64.56,21.15 C64.8781116,21.1415295 65.1892498,21.2440637 65.44,21.44 C65.6928398,21.6632336 65.8230494,21.9943381 65.79,22.33 C65.805553,22.649276 65.6814528,22.9595263 65.45,23.18 Z"
            id="Shape"
          />
          <path
            d="M74.3,19.65 L70.89,19.65 L70.89,28.06 L72.51,28.06 L72.51,25 L74.3,25 C75.0731015,25.0425688 75.8327239,24.7845839 76.42,24.28 C76.961482,23.7611228 77.2437224,23.0280309 77.19,22.28 C77.2237619,21.5438619 76.9310294,20.8303263 76.39,20.33 C75.8044351,19.8471126 75.0576027,19.6041241 74.3,19.65 Z M75.17,23.18 C74.9240332,23.3946269 74.6063217,23.5088603 74.28,23.5 L72.51,23.5 L72.51,21.15 L74.28,21.15 C74.5981116,21.1415295 74.9092498,21.2440637 75.16,21.44 C75.4128398,21.6632336 75.5430494,21.9943381 75.51,22.33 C75.525553,22.649276 75.4014528,22.9595263 75.17,23.18 Z"
            id="Shape"
          />
          <path
            d="M86.3,20.54 C85.9657924,20.2022325 85.568191,19.9337667 85.13,19.75 C84.266,19.39 83.294,19.39 82.43,19.75 C81.4842874,20.1444406 80.7551357,20.9282786 80.43,21.9 C80.2194529,22.5042098 80.1145856,23.1401795 80.12,23.78 C80.1159174,24.4589398 80.2205756,25.1341537 80.43,25.78 C80.5917908,26.3029241 80.8763843,26.7795325 81.26,27.17 C81.5890464,27.5019329 81.9842826,27.7608807 82.42,27.93 C82.8495158,28.1001396 83.3080414,28.1850518 83.77,28.18 C84.2339619,28.2077755 84.6989594,28.1466809 85.14,28 C85.5743805,27.8282918 85.9691139,27.5696734 86.3,27.24 C86.6767105,26.8459827 86.9573355,26.370289 87.12,25.85 C87.3339787,25.205246 87.4387538,24.5292775 87.43,23.85 C87.4402081,23.2098164 87.3352036,22.5730147 87.12,21.97 C86.9570464,21.4380089 86.6768342,20.9493462 86.3,20.54 Z M85.2,26.05 C84.8653031,26.4963378 84.3378522,26.7563488 83.78,26.75 C83.214513,26.7500708 82.6860074,26.4689508 82.37,26 C81.9430666,25.3310921 81.7464194,24.5409914 81.81,23.75 C81.7617472,23.0092757 81.9623683,22.2736649 82.38,21.66 C82.7051652,21.2117662 83.2262494,20.947502 83.78,20.95 C84.3325908,20.9523544 84.8516052,21.2155689 85.18,21.66 C85.5976317,22.2736649 85.7982528,23.0092757 85.75,23.75 C85.8232065,24.55619 85.6300005,25.3641421 85.2,26.05 Z"
            id="Shape"
          />
          <path
            d="M97.1,23.88 C97.8352643,22.7830384 97.6973397,21.3202018 96.77,20.38 C96.1918761,19.879116 95.4442559,19.6179855 94.68,19.65 L91.27,19.65 L91.27,28.06 L92.89,28.06 L92.89,24.94 L94.14,24.94 L95.83,28.06 L97.58,28.06 L95.79,24.78 C96.3104946,24.6253268 96.7689206,24.3103776 97.1,23.88 Z M94.55,23.53 L92.89,23.53 L92.89,21.12 L94.55,21.12 C95.47,21.12 95.93,21.52 95.93,22.33 C95.93,23.14 95.47,23.53 94.55,23.53 Z"
            id="Shape"
          />
          <polygon
            id="Shape"
            points="100.46 21.16 102.92 21.16 102.92 28.05 104.54 28.05 104.54 21.16 107 21.16 107 19.66 100.46 19.66"
          />
          <path
            d="M115,25.27 C115.019054,25.6645812 114.878492,26.0502256 114.61,26.34 C114.294493,26.6189849 113.880127,26.7595093 113.46,26.73 C113.037677,26.7627307 112.620852,26.6177482 112.31,26.33 C112.039953,26.0371554 111.899358,25.6478153 111.92,25.25 L111.92,19.65 L110.3,19.65 L110.3,25.32 C110.249014,26.1324131 110.564565,26.9249582 111.16,27.48 C112.530117,28.4805291 114.389883,28.4805291 115.76,27.48 C116.35907,26.9274506 116.675339,26.1331013 116.62,25.32 L116.62,19.65 L115,19.65 L115,25.27 Z"
            id="Shape"
          />
          <polygon
            id="Shape"
            points="125.48 25.16 125.46 25.16 122.39 19.66 120.77 19.66 120.77 28.05 122.39 28.05 122.39 22.55 122.41 22.55 125.52 28.05 127.11 28.05 127.11 19.66 125.48 19.66"
          />
          <rect
            id="Rectangle-path"
            x="131.49"
            y="19.65"
            width="1.62"
            height="8.4"
          />
          <polygon
            id="Shape"
            points="136.65 21.16 139.11 21.16 139.11 28.05 140.73 28.05 140.73 21.16 143.19 21.16 143.19 19.66 136.65 19.66"
          />
          <polygon
            id="Shape"
            points="149.64 23.1 149.61 23.1 147.81 19.66 146.07 19.66 148.82 24.52 148.82 28.05 150.43 28.05 150.43 24.52 153.18 19.66 151.44 19.66"
          />
        </g>
      </g>
    </svg>
  </Logo>
);

export default EqualHousingOpportunityLogo;
