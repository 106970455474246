import styled, { css } from "styled-components";
import { layout } from "../../main/styles/layout";
import { h1, h3, defaultText } from "../../main/styles/typography";
import { btn } from "../../main/styles/buttons";

export const FieldsWrapper = styled.div`
  ${layout("column", "center")}
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 41px 39px 130px 39px;
  ${(props) => props.leadType === "buyer" && `padding-top: 100px;`}
  font-family: ${(props) => props.font};
  box-sizing: border-box;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  @media (max-width: 599px) {
    padding: 40px 15px 0 15px;
  }
`;

export const FieldInputs = styled.div`
  ${(props) =>
    props.fieldCount > 1
      ? css`
          ${layout("row", "center", "space-between")}
        `
      : css`
          ${layout("column", "center")}
        `}
  flex-wrap: wrap;
  width: 100%;
  max-width: 554px;
  margin: 0 auto;
`;

export const ButtonWrapper = styled.div`
  ${layout("row", "center", "center")}
`;

export const Button = styled.button`
  ${btn()}
  color: white;
  margin-bottom: 48px;
  background-color: ${(props) => props.color};
  border-bottom: 2px solid ${(props) => props.accentColor};
  opacity: ${(props) => (props.disabled ? ".5" : "1")};
`;

export const CopyContainer = styled.div`
  ${layout("column", "center")}
  flex-wrap: nowrap;
  & h1 {
    ${h1()}
    color: #4A4A4A;
    padding-bottom: 40px;
    @media (max-width: 599px) {
      padding-bottom: 30px;
    }
  }
  & h3 {
    ${h3()}
    color: #4A4A4A;
    padding-bottom: 40px;
  }
  & p {
    ${defaultText()}
    color: #4A4A4A;
    padding-bottom: 40px;
  }
`;
