import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import { layout, boxShadow } from "../../main/styles/layout";
import CONFIG from "../constants/config";
import COLORS from "../../main/constants/colors.js";

const ZOOM_LEVEL = 15;

export const getEncodedGeoJson = (lon, lat, markerColor) => {
  const geoJson = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [lon, lat]
    },
    properties: {
      "marker-color": markerColor,
      "marker-size": "large",
      "marker-symbol": "star"
    }
  };
  return encodeURIComponent(JSON.stringify(geoJson));
};

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };

    this.handleLoad = this.handleLoad.bind(this);
  }
  handleLoad() {
    this.setState({ loaded: true });
  }
  render() {
    const { lon, lat, markerColor } = this.props;
    const geoJson = getEncodedGeoJson(lon, lat, markerColor);
    const mapUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/geojson(${geoJson})/${lon},${lat},${ZOOM_LEVEL}/200x200@2x?access_token=${CONFIG.mapbox.token}`;

    if (!lon || !lat) {
      return <BlankMapSpace />;
    }
    return (
      <MapWrapper>
        <MapImage
          loaded={this.state.loaded}
          onLoad={this.handleLoad}
          src={mapUrl}
          alt="property location map"
        />
      </MapWrapper>
    );
  }
}

Map.propTypes = {
  lon: PropTypes.number,
  lat: PropTypes.number,
  markerColor: PropTypes.string.isRequired
};

// Styled Components
const MapWrapper = styled.div`
  ${layout("column", "center", "center")}
  ${boxShadow()}
  width: 200px;
  height: 200px;
  margin: -100px auto 0 auto;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
  background-color: ${COLORS.lightestGray};
  @media (max-width: 599px) {
    margin-top: 30px;
  }
`;

const BlankMapSpace = styled.div`
  ${layout("column", "center", "center")}
  width: 200px;
  height: 200px;
  margin: -100px auto 0 auto;
  overflow: hidden;
`;

const MapImage = styled.img`
  width: 200px;
  height: 200px;
  opacity: ${(props) => (props.loaded ? 1 : 0)};
  transition: opacity 0.2s linear;
`;

export default Map;
