import Seller1 from "./Seller1";
import Seller2 from "./Seller2";
import Seller3 from "./Seller3";
import Seller4 from "./Seller4";
import Seller5 from "./Seller5";
import Seller6 from "./Seller6";
import Seller7 from "./Seller7";
import Seller8 from "./Seller8";
import Seller9 from "./Seller9";
import Seller10 from "./Seller10";
import Seller11 from "./Seller11";
import Seller12 from "./Seller12";
import Seller13 from "./Seller13";
import Seller14 from "./Seller14";
import Seller15 from "./Seller15";
import Seller16 from "./Seller16";
import Buyer1 from "./Buyer1";
import Buyer2 from "./Buyer2";
import Buyer3 from "./Buyer3";
import Buyer4 from "./Buyer4";
import Buyer5 from "./Buyer5";
import Buyer6 from "./Buyer6";
import Buyer7 from "./Buyer7";
import Buyer8 from "./Buyer8";
import Buyer9 from "./Buyer9";
import Buyer10 from "./Buyer10";
import Buyer11 from "./Buyer11";
import Buyer12 from "./Buyer12";
import Buyer13 from "./Buyer13";
import Buyer14 from "./Buyer14";
import Buyer15 from "./Buyer15";
import Buyer16 from "./Buyer16";
import Customizable from "./Customizable";

const DESIGNS = {
  seller: {
    1: Seller1,
    2: Seller2,
    3: Seller3,
    4: Seller4,
    5: Seller5,
    6: Seller6,
    7: Seller7,
    8: Seller8,
    9: Seller9,
    10: Seller10,
    11: Seller11,
    12: Seller12,
    13: Seller13,
    14: Seller14,
    15: Seller15,
    16: Seller16
  },
  buyer: {
    1: Buyer1,
    2: Buyer2,
    3: Buyer3,
    4: Buyer4,
    5: Buyer5,
    6: Buyer6,
    7: Buyer7,
    8: Buyer8,
    9: Buyer9,
    10: Buyer10,
    11: Buyer11,
    12: Buyer12,
    13: Buyer13,
    14: Buyer14,
    15: Buyer15,
    16: Buyer16
  }
};

export const getDesignComponent = (leadType, id) => {
  if (id === 0) {
    return Customizable;
  }
  return DESIGNS[leadType][id];
};
