import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PrimaryEmail from "../components/PrimaryEmail";

import { changeFieldValue, submitPrimary } from "../actions/formActions";

import { getFormField } from "../store/selectors";

export const mapStateToProps = (state) => {
  const { design } = state.page;
  const { value } = getFormField(state, "email");
  return {
    design,
    inputValue: value || ""
  };
};

export const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changeFieldValue,
      submitPrimary
    },
    dispatch
  );
};

const PrimaryEmailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrimaryEmail);

export default PrimaryEmailContainer;
