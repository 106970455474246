import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import COLORS from "../../main/constants/colors";
import KEYS from "../../main/constants/keys";

// This Module uses Styled Components for styling.  You write CSS in a template string literal.
// You can find the docs here: https://github.com/styled-components/styled-components

class TextInputField extends Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }
  handleKeyDown(e) {
    const { name } = this.props.field;
    const code = e.which ? e.which : e.keyCode;

    if (
      ["sqft"].includes(name) &&
      (code < 48 || code > 57) && // Not a number at top of keyboard
      (code < 96 || code > 105) && // Not a number on numpad
      !KEYS[code]
    ) {
      // Not one of our control keys (eg, enter, esc, etc)
      return e.preventDefault();
    }
  }
  handleOnChange(e) {
    return this.props.onChanged(this.props.field.name, e.target.value);
  }
  render() {
    const {
      font,
      field: { name },
      autoFocus,
      errorMessage
    } = this.props;
    return (
      <TextInput
        type="text"
        name={name}
        id={name}
        font={font}
        autoFocus={autoFocus}
        hasError={errorMessage}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleOnChange}
      />
    );
  }
}

TextInputField.propTypes = {
  font: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  onChanged: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string
};

// Styled Components
const TextInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 42px;
  background-color: #ffffff;
  border: ${(props) =>
    props.hasError
      ? `2px solid ${COLORS.errorRed}`
      : `2px solid ${COLORS.lightGray}`};
  border-radius: 3px;
  outline: none;
  padding: 8px 10px;
  font-size: 15px;
  line-height: 20px;
  color: #566373;
  font-family: ${(props) => props.font};
  &::placeholder {
    font-size: 15px;
    line-height: 20px;
    color: #c5c9d1;
  }
`;

export default TextInputField;
