export const getFormField = (state, name) => {
  return state.form.fields.find((f) => f.name === name);
};

export const getFormFields = (state) => state.form.fields;

export const getFieldError = (state, name) => getFormErrors(state)[name];

export const getFormErrors = (state) => state.form.errors;

export const getPageId = (state) => state.page.id;

export const getPageGuid = (state) => state.page.guid;

export const getLeadData = (state) => {
  const fields = getFormFields(state);
  return fields.reduce((data, field) => {
    return { ...data, [field.name]: field.value };
  }, {});
};

export const getAddress = (state) => state.address;

export const getAddressLatLon = (state) => ({
  lat: state.address.coordinates[1],
  lon: state.address.coordinates[0]
});

export const getCaptureId = (state) => state.capture.id;

export const getAddressQuery = (state) => state.typeahead.query;
