import PropTypes from "prop-types";
import React, { Component } from "react";

import styled from "styled-components";
import { layout } from "../../main/styles/layout";
import { smallText } from "../../main/styles/typography";
import AttractFooterTooltip from "./AttractFooterTooltip";
import EqualHousingOpportunityLogo from "./EqualHousingOpportunityLogo";

import { formatWebsiteUrl } from "../lib/url-utils";

class PageAttractFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipVisible: true
    };
    this.toggleTooltipVisibility = this.toggleTooltipVisibility.bind(this);
  }

  // NOTE: Hackiness to make Customize Mobile view work consistently
  componentDidMount() {
    this.setState({ tooltipVisible: false });
  }

  toggleTooltipVisibility(e) {
    if (!e.target.hasAttribute("href")) {
      this.setState({ tooltipVisible: !this.state.tooltipVisible });
    }
  }

  render() {
    const { customizable, dreNumber, website, design, alignment } = this.props;
    const { font, textColor } = design;

    if (!dreNumber && !website) {
      return null;
    }

    const dreNumberItem = dreNumber ? (
      <DreNumber font={font} color={textColor}>
        DRE #{dreNumber}
      </DreNumber>
    ) : null;

    const websiteItem = website ? (
      <WebsiteLink
        font={font}
        color={textColor}
        href={formatWebsiteUrl(website)}
        target="_blank">
        {" "}
        {website}
      </WebsiteLink>
    ) : null;

    return (
      <Footer font={font} alignment={alignment}>
        <ContentWrapper alignment={alignment}>
          {customizable ? (
            <EditableWrapper
              alignment={alignment}
              onClick={this.toggleTooltipVisibility}>
              {dreNumberItem}
              {websiteItem}
            </EditableWrapper>
          ) : (
            <StaticWrapper alignment={alignment}>
              {dreNumberItem}
              {websiteItem}
            </StaticWrapper>
          )}

          {customizable ? (
            <AttractFooterTooltip
              visible={this.state.tooltipVisible}
              closeTooltip={this.toggleTooltipVisibility}
            />
          ) : null}

          <EqualHousingOpportunityLogo color={textColor} />
        </ContentWrapper>
      </Footer>
    );
  }
}

PageAttractFooter.propTypes = {
  customizable: PropTypes.bool,
  dreNumber: PropTypes.string.isRequired,
  website: PropTypes.string,
  design: PropTypes.object.isRequired,
  alignment: PropTypes.string
};

const getAlignment = (alignment) => {
  switch (alignment) {
    case "left":
      return "flex-start";
    case "center":
      return "center";
    case "right":
      return "flex-end";
    default:
      return "center";
  }
};

// Styled Components
const Footer = styled.footer`
  ${(props) => layout("column", getAlignment(props.alignment))} width: 100%;
  max-width: 1200px;
  font-family: ${(props) => props.font};
  @media (max-width: 600px) {
    ${layout("column", "center")} top: 0;
  }
`;

const ContentWrapper = styled.div`
  ${(props) =>
    layout("column", getAlignment(props.alignment))} position: relative;
  padding: 6px 24px;
  box-sizing: border-box;
  @media (max-width: 600px) {
    ${layout("column", "center")} padding: 30px 0;
  }
  @media only screen and (max-device-width: 768px) and (orientation: landscape) {
    ${layout("row", "center", "space-between")}
    bottom: 0;
    width: 100%;
  }
`;

const EditableWrapper = styled.div`
  ${(props) =>
    layout("column", getAlignment(props.alignment))} padding: 6px 24px;
  box-sizing: border-box;
  max-width: 250px;
  border: 1px dashed #00e6c3;
  cursor: pointer;
  &:hover {
    border: 1px solid #00e6c3;
  }
  @media (max-width: 599px) {
    ${layout("column", "center")};
  }
`;

const StaticWrapper = styled.div`
  ${(props) =>
    layout("column", getAlignment(props.alignment))} padding: 6px 24px;
  box-sizing: border-box;
  max-width: 250px;
  @media (max-width: 599px) {
    ${layout("column", "center")};
  }
`;

const DreNumber = styled.p`
  ${smallText} font-family: ${(props) => props.font};
  color: ${(props) => props.color};
`;

const WebsiteLink = styled.a`
  ${smallText} font-family: ${(props) => props.font};
  color: ${(props) => props.color};
`;

export default PageAttractFooter;
