import PropTypes from "prop-types";
import React from "react";
import { ConfirmLayout } from "./ConfirmationStyledComponents";
import ConfirmBuyerArtwork from "./ConfirmBuyerArtwork";

const ConfirmBuyerLayout = ({ children, design }) => {
  const { accentColor, font } = design;
  return (
    <ConfirmLayout font={font}>
      {children}
      <ConfirmBuyerArtwork accentColor={accentColor} />
    </ConfirmLayout>
  );
};

ConfirmBuyerLayout.propTypes = {
  children: PropTypes.node.isRequired,
  design: PropTypes.object.isRequired
};

export default ConfirmBuyerLayout;
