const COLORS = {
  primary: "#FD5154",
  primaryDark: "#E4383B",
  primaryLight: "#FF6B6E",
  darkGray: "#26303E",
  mediumGray: "#566373",
  mediumLightGray: "#7b8592",
  lightGray: "#C5C9D1",
  lighterGray: "#E1E6E9",
  lightestGray: "#FAFAFA",
  white: "#FFFFFF",
  black: "#000000",
  green: "#2DC878",
  errorRed: "#EC5252",
  highlightBlue: "rgba(74, 144, 226, 0.10)",
  highlightYellow: "#FEF9C3",
  notificationSuccess: "#CCF4E3",
  notificationConfirmation: "#FDEECD",
  notificationError: "#FBDCDC",
  notificationInfo: "#D6E5F3"
};

export default COLORS;
