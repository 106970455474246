import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";

class ModalCloser extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    Array.from(this.elem.parentNode.children)
      .filter((e) => e !== this.elem)
      .forEach((sibling) => {
        let currentZIndex = getComputedStyle(sibling).zIndex;
        sibling.style.zIndex =
          currentZIndex === "auto"
            ? "1"
            : (parseInt(currentZIndex) + 1).toString();
      });
  }

  render() {
    return (
      <Closer
        onClick={this.props.onClick}
        ref={(elem) => (this.elem = elem)}></Closer>
    );
  }
}

ModalCloser.propTypes = {
  onClick: PropTypes.func.isRequired
};

// Styled Component
export const Closer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  z-index: 0;
`;

export default ModalCloser;
