import PropTypes from "prop-types";
import React from "react";
import { CopyContainer } from "./FieldsStyledComponents";

const FieldsCopyFollowUp = ({ leadType, address }) => {
  switch (leadType) {
    case "seller":
      return (
        <CopyContainer>
          <h1>Found It!</h1>
          <h3>{address}</h3>
          <p>
            Please let us know where we should email your home valuation report:
          </p>
        </CopyContainer>
      );
    case "buyer":
      return (
        <CopyContainer>
          <h1>A Few More Details</h1>
        </CopyContainer>
      );
  }
};

FieldsCopyFollowUp.propTypes = {
  leadType: PropTypes.string.isRequired,
  address: PropTypes.string
};

export default FieldsCopyFollowUp;
