import PropTypes from "prop-types";
import React, { Component } from "react";

import AgentInfoTooltip from "./AgentInfoTooltip";
import AvatarOrInitials from "../../main/components/AvatarOrInitials";
import { formatPhoneNumber } from "../lib/viewHelpers";
import styled, { css } from "styled-components";
import { layout } from "../../main/styles/layout";
import { materialIcon } from "../../main/styles/typography";

class PageAgentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipVisible: true
    };
    this.toggleTooltipVisibility = this.toggleTooltipVisibility.bind(this);
  }

  // NOTE: Hackiness to make Customize Mobile view work consistently
  componentDidMount() {
    this.setState({ tooltipVisible: false });
  }

  toggleTooltipVisibility(e) {
    if (!e.target.hasAttribute("href")) {
      this.setState({ tooltipVisible: !this.state.tooltipVisible });
    }
  }

  render() {
    const {
      agent,
      design: { font, textColor },
      customizable
    } = this.props;
    const {
      avatar,
      name,
      companyName,
      email,
      phoneNumber,
      preferences: { avatarShape }
    } = agent;

    return (
      <OuterWrapper style={{ fontFamily: font, color: textColor }}>
        <InnerWrapper
          onClick={this.toggleTooltipVisibility}
          customizable={customizable}>
          <AvatarOrInitials
            url={avatar.small}
            email={email}
            name={name}
            size="medium"
            shape={avatarShape}
          />
          <NameAndCompany style={{ color: textColor }}>
            <AgentName>{name}</AgentName>
            <CompanyName>{companyName}</CompanyName>
          </NameAndCompany>
          <ContactInfo style={{ color: textColor }}>
            <PhoneNumber
              href={`tel:${phoneNumber}`}
              style={{ color: textColor }}>
              {formatPhoneNumber(phoneNumber)}
            </PhoneNumber>
            <Email href={`mailto:${email}`} style={{ color: textColor }}>
              {email}
            </Email>
          </ContactInfo>
          <MiniContactInfo>
            <PhoneNumber href={`tel:${phoneNumber}`}>
              <MaterialIcon style={{ color: textColor, marginRight: "15px" }}>
                phone
              </MaterialIcon>
            </PhoneNumber>
            <Email href={`mailto:${email}`}>
              <MaterialIcon style={{ color: textColor }}>email</MaterialIcon>
            </Email>
          </MiniContactInfo>
          {customizable ? (
            <AgentInfoTooltip
              visible={this.state.tooltipVisible}
              closeTooltip={this.toggleTooltipVisibility}
            />
          ) : null}
        </InnerWrapper>
      </OuterWrapper>
    );
  }
}

PageAgentInfo.propTypes = {
  agent: PropTypes.shape({
    name: PropTypes.string,
    companyName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string
  }).isRequired,
  design: PropTypes.object.isRequired,
  customizable: PropTypes.bool
};

// Styled Components
const OuterWrapper = styled.div`
  ${layout("column", "center")}
  position: absolute;
  top: 18px;
  right: 0;
  left: 0;
  @media (max-width: 599px) {
    top: 0;
  }
`;

const InnerWrapper = styled.div`
  ${layout("row", "center")}
  position: relative;
  width: 100%;
  max-width: 1190px;
  padding: 12px 24px;
  box-sizing: border-box;
  ${(props) =>
    props.customizable &&
    css`
      border: 1px dashed #00e6c3;
      cursor: pointer;
      &:hover {
        border: 1px solid #00e6c3;
      }
    `}
  @media (max-width: 599px) {
    ${layout("column", "center", "center")}
    padding: 30px 0 20px 0;
  }
`;

const NameAndCompany = styled.div`
  ${layout("column")}
  margin-left: 30px;
  @media (max-width: 599px) {
    ${layout("column", "center")}
    margin: 14px 0 0 0;
  }
`;

const AgentName = styled.span`
  font-size: 15px;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 2px;
`;

const CompanyName = styled.span`
  font-size: 13px;
  line-height: 20px;
`;

const ContactInfo = styled.div`
  ${layout("column", "flex-end")}
  margin-left: auto;
  @media (max-width: 599px) {
    display: none;
  }
`;

const MiniContactInfo = styled.div`
  display: none;
  @media (max-width: 599px) {
    position: absolute;
    ${layout("row", "center", "flex-end")}
    top: 30px;
    right: 12px;
  }
`;

const PhoneNumber = styled.a`
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 2px;
  cursor: pointer;
  text-decoration: none;
`;

const Email = styled.a`
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: underline;
`;

const MaterialIcon = styled.i`
  ${materialIcon()}
  cursor: pointer;
`;

export default PageAgentInfo;
