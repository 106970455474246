import PropTypes from "prop-types";
import React, { Component } from "react";
import Textarea from "react-textarea-autosize";
import styled from "styled-components";
import COLORS from "../../main/constants/colors";

class FieldsTextAreaInput extends Component {
  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(e) {
    return this.props.onChanged(this.props.field.name, e.target.value);
  }

  render() {
    const { font } = this.props;

    return (
      <TextAreaWrapper font={font}>
        <Textarea useCacheForDOMMeasurements onChange={this.handleOnChange} />
      </TextAreaWrapper>
    );
  }
}

FieldsTextAreaInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  font: PropTypes.string.isRequired,
  onChanged: PropTypes.func.isRequired
};

// Styled Components
const TextAreaWrapper = styled.div`
  width: 100%;
  & textarea {
    min-height: 88px;
    max-height: 230px;
    border: 2px solid ${COLORS.lightGray};
    border-radius: 4px;
    padding: 4px 8px;
    box-sizing: border-box;
    width: 100%;
    outline: none;
    font-family: ${(props) => props.font};
    font-size: 15px;
    line-height: 24px;
    color: var(--medium-gray);
    resize: none;
  }
`;

export default FieldsTextAreaInput;
