const KEYS = {
  8: "backspace",
  9: "tab",
  37: "left",
  38: "up",
  39: "right",
  40: "down",
  13: "enter",
  27: "esc"
};

export default KEYS;
