import PropTypes from "prop-types";
import React from "react";

const EmailTag = ({ email }) => {
  return <a href={`mailto:${email}`}>{email}</a>;
};

EmailTag.propTypes = {
  email: PropTypes.string.isRequired
};

export default EmailTag;
