import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Map from "../components/Map";

export const MapContainer = ({ leadType, ...otherProps }) => {
  if (leadType === "buyer") {
    return null;
  }
  return <Map {...otherProps} />;
};

MapContainer.propTypes = {
  leadType: PropTypes.string.isRequired,
  lon: PropTypes.number,
  lat: PropTypes.number,
  markerColor: PropTypes.string
};

export const mapStateToProps = ({ page, address }) => {
  const { leadType } = page;
  const [lon, lat] = address.coordinates;
  const { accentColor: markerColor } = page.design;
  return {
    leadType,
    lon,
    lat,
    markerColor
  };
};

export const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
