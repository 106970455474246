import PropTypes from "prop-types";
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router";
import "autotrack/lib/plugins/url-change-tracker";
import routes from "../routes";

// TODO: Figure out the best path to server side rendering...
//  react-rails vs straight webpack

const Root = ({ store, history }) => {
  return (
    <Provider store={store}>
      <Router history={history} routes={routes} />
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Root;
