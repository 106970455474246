// TODO: Investigate using redux middleware to simplify api calls
//  throughout app without passing the agent from the redux store

import "whatwg-fetch";

export const get = (url) => {
  return fetch(url, {
    method: "GET",
    headers: new Headers({
      "X-Key-Inflection": "camel"
    })
  });
};

export const post = (url, body) => {
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: new Headers({
      "Content-Type": "application/json",
      "X-Key-Inflection": "camel"
    })
  });
};

export const createCapture = (pageId, data) => {
  return post(`/api/pages/${pageId}/captures`, {
    capture: { data }
  });
};

export const updateCapture = (pageId, captureId, leadData) => {
  return post(`/api/pages/${pageId}/captures`, {
    capture: {
      partialCaptureId: captureId,
      data: leadData
    }
  });
};

export const fetchResults = (query, sessionId) => {
  return get(`/api/geocode?query=${query}&sessionId=${sessionId}`);
};

export default {
  get,
  post
};
