export const urlStartsWithHttp = (url) => url.slice(0, 7) === "http://";
export const urlStartsWithHttps = (url) => url.slice(0, 8) === "https://";

export const formatWebsiteUrl = (website) => {
  if (!website) {
    return null;
  } else if (urlStartsWithHttp(website) || urlStartsWithHttps(website)) {
    return website;
  } else {
    return `http://${website}`;
  }
};
