import PropTypes from "prop-types";
import React from "react";
import { formatPhoneNumber } from "../lib/viewHelpers";

const PhoneTag = ({ phoneNumber }) => {
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
  return <a href={`tel:${phoneNumber}`}>{formattedPhoneNumber}</a>;
};

PhoneTag.propTypes = {
  phoneNumber: PropTypes.string.isRequired
};

export default PhoneTag;
