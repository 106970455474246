import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import COLORS from "../constants/colors";

const SIZES = {
  xsmall: 32,
  small: 48,
  medium: 67,
  large: 120
};

const FONT_SIZES = {
  xsmall: 15,
  small: 18,
  medium: 24,
  large: 36
};

export const getColorFromInitials = (initials) => {
  const first = initials[0];
  if (["A", "B", "C", "D"].includes(first)) {
    return "#00CA72";
  } else if (["E", "F", "G", "H"].includes(first)) {
    return "#00C9C7";
  } else if (["I", "J", "K", "L"].includes(first)) {
    return "#5294EC";
  } else if (["M", "N", "O", "P"].includes(first)) {
    return "#E352EC";
  } else if (["Q", "R", "S", "T"].includes(first)) {
    return "#5265EC";
  } else if (["U", "V", "W", "X"].includes(first)) {
    return "#9B52EC";
  } else if (["Y", "Z"].includes(first)) {
    return "#F6A807";
  }
  return "#5294EC";
};

export const getInitials = (email, name) => {
  if (name) {
    const last = name.includes(" ")
      ? name.slice(name.lastIndexOf(" ") + 1)
      : "";
    return `${name.charAt(0)}${last.charAt(0)}`.toUpperCase();
  } else {
    return email[0].toUpperCase();
  }
};

// sizes: 'xsmall', 'small', 'medium', 'large'
const Initials = ({ email, name, size = "small" }) => {
  const initials = getInitials(email, name);
  const backgroundColor = getColorFromInitials(initials);
  return (
    <CircularContainer bgColor={backgroundColor} size={size}>
      {initials}
    </CircularContainer>
  );
};

Initials.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string,
  size: PropTypes.string
};

// Styled Component
const CircularContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: ${COLORS.white};
  background-color: ${(props) => props.bgColor};
  min-width: ${(props) => SIZES[props.size]}px;
  max-width: ${(props) => SIZES[props.size]}px;
  height: ${(props) => SIZES[props.size]}px;
  font-size: ${(props) => FONT_SIZES[props.size]}px;
  font-weight: normal;
  user-select: none;
`;

export default Initials;
