import PropTypes from "prop-types";
import React, { Component } from "react";
import _debounce from "lodash.debounce";
import PrimaryAddress from "./PrimaryAddress";
import TypeaheadResults from "./TypeaheadResults";
import KEYS from "../../main/constants/keys";
import styled from "styled-components";
import { layout } from "../../main/styles/layout";

class AddressTypeahead extends Component {
  constructor(props) {
    super(props);

    this.updateResultsDebounced = _debounce(props.updateResults, 250);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleResultClick = this.handleResultClick.bind(this);
    this.handleResultMouseEnter = this.handleResultMouseEnter.bind(this);
    this.handleResultMouseLeave = this.handleResultMouseLeave.bind(this);
  }
  handleKeyDown(e) {
    switch (KEYS[e.keyCode]) {
      case "up":
        e.preventDefault();
        return this.props.traverseUp();
      case "down":
        e.preventDefault();
        return this.props.traverseDown();
      case "enter":
        e.preventDefault();
        if (this.props.activeIndex > -1) {
          this.props.selectResult(this.props.results[this.props.activeIndex]);
        } else {
          this.handleSubmit();
        }
        break;
      default:
        return;
    }
  }
  handleInputChange(e) {
    this.props.setAddressQuery(e.target.value);
    this.props.setActiveIndex(-1);
    this.updateResultsDebounced();
  }
  handleSubmit() {
    if (this.props.query) {
      this.props.submitPrimary("address");
    }
  }
  handleInputBlur() {
    this.props.clearResults();
    this.props.setActiveIndex(-1);
  }
  handleInputFocus() {
    this.updateResultsDebounced();
  }
  handleResultClick(result) {
    this.props.selectResult(result);
  }
  handleResultMouseEnter(index) {
    this.props.setActiveIndex(index);
  }
  handleResultMouseLeave() {
    this.props.setActiveIndex(-1);
  }
  render() {
    const { query, results, activeIndex, design } = this.props;

    return (
      <TypeaheadWrapper>
        <PrimaryAddress
          inputValue={query}
          design={design}
          onChange={this.handleInputChange}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputBlur}
          onKeyDown={this.handleKeyDown}
          onSubmit={this.handleSubmit}
        />
        <TypeaheadResults
          design={design}
          results={results}
          activeIndex={activeIndex}
          onResultSelected={this.handleResultClick}
          onResultMouseEntered={this.handleResultMouseEnter}
          onResultMouseLeft={this.handleResultMouseLeave}
        />
      </TypeaheadWrapper>
    );
  }
}

AddressTypeahead.propTypes = {
  query: PropTypes.string.isRequired,
  address: PropTypes.object.isRequired,
  results: PropTypes.array.isRequired,
  activeIndex: PropTypes.number.isRequired,
  design: PropTypes.object.isRequired,
  traverseUp: PropTypes.func.isRequired,
  traverseDown: PropTypes.func.isRequired,
  setActiveIndex: PropTypes.func.isRequired,
  setAddressQuery: PropTypes.func.isRequired,
  updateResults: PropTypes.func.isRequired,
  clearResults: PropTypes.func.isRequired,
  selectResult: PropTypes.func.isRequired,
  submitPrimary: PropTypes.func.isRequired
};

// Styled Components
const TypeaheadWrapper = styled.div`
  ${layout("column")}
  position: relative;
  width: 100%;
  max-width: 556px;
`;

export default AddressTypeahead;
