import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getFormField } from "../store/selectors";
import Confirmation from "../components/Confirmation";

export const mapStateToProps = (state) => {
  const { page, agent } = state;
  const { leadType, design } = state.page;
  const confirmType = page.deliverables.length
    ? page.deliverables[0].name
    : "followUp";
  const leadEmail = getFormField(state, "email").value;
  return {
    leadType,
    confirmType,
    agent,
    leadEmail,
    design
  };
};

export const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

const ConfirmationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirmation);

export default ConfirmationContainer;
